import { IntakeProvider } from '../../contexts/IntakeContext';
import IntakeStages from './stages/IntakeStages';

const NewIntake = () => {
  return (
    <IntakeProvider>
      <IntakeStages />
    </IntakeProvider>
  );
};

export default NewIntake;
