import React, { useContext, useState } from 'react'
import Select, { components } from 'react-select'
import { generateTimeSlots } from '../../../lib/date';
import { AppStateContext } from '../../../contexts/AppStateContext';
import { addSlot, deleteSlot, modifySlot } from '../../../lib/api_service/employees';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const EmployeeSlotForm = () => {
  const defaultSlot = {
    week_day: 0,
    start_time: '',
    end_time: '',
    week_day_label: '',
    slot_products: []
  }
  const defaultFormSalonProduct = {}
  const defaultEmployee = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    products: []
  }
  const { salonState, formSlotState, employeeFormState } = useContext(AppStateContext);
  const [salon,,] = salonState;
  const [,,formSlot, setFormSlot] = formSlotState
  const [formSalonProduct,] = useState(defaultFormSalonProduct)
  const [editingEmployee,setEditingEmployee,,saveSalonEmployee] = employeeFormState;
  const availableSlots = generateTimeSlots()
  const { t } = useTranslation();

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{props.data.label}</span>
          {
            props.data.mode === 'online' ? <div className='badge badge-success badge-outline'>
              {t('videoCall')}
            </div> : null
          }
        </div>
      </components.Option>
    );
  };

  // Custom SingleValue component
  const CustomSingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{props.data.label}</span>
          {
            props.data.mode === 'online' ? <div className='badge badge-success badge-outline'>
              {t('videoCall')}
            </div> : null
          }
        </div>
      </components.SingleValue>
    );
  };

  const handleSlotStartChange = (time) => {
    setFormSlot({
      ...formSlot,
      start_time: time.value
    })
  }

  const handleSlotEndChange = (time) => {
    setFormSlot({
      ...formSlot,
      end_time: time.value
    })
  }

  const handleSlotProductChange = (product) => {
    const salonProduct = salon.salon_products.find(sp => sp.id === product.value);
    setFormSlot({
      ...formSlot,
      slot_products: [...formSlot.slot_products, { salon_product: salonProduct }]
    })
  }

  const handleRemoveSlotProduct = (slotProduct) => {
    let newSlotProducts = [...formSlot.slot_products].filter((sp) => {
      return !(sp.salon_product.id === slotProduct.salon_product.id)
    })
    setFormSlot({
      ...formSlot,
      slot_products: newSlotProducts
    })
  }

  const unselectedSalonProducts = () => {
    return (
      editingEmployee.salon_products?.filter((salon_p) => {
        const selectedSalonProductIds = formSlot.slot_products.map((slot_p) => { return slot_p.salon_product.id })
        return !selectedSalonProductIds.includes(salon_p.id)
      })
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (formSlot.id) {
      modifySlot(salon, editingEmployee, formSlot)
        .then(response => response.json())
        .then((data) => {
          if (data.errors) {
            toast.error(t('schedule.update.error', { error: data.errors }))
          } else {
            saveSalonEmployee(data)
            toast.success(t('schedule.update.success'))
            resetForm()
          }
        })
    } else {
      addSlot(salon, editingEmployee, formSlot)
        .then(response => response.json())
        .then((data) => {
          if (data.errors) {
            toast.error(t('schedule.update.error', { error: data.errors }))
          } else {
            saveSalonEmployee(data)
            toast.success(t('schedule.update.success'))
            resetForm()
          }
        })
    }
    document.getElementById('employee-slot-form').close()
  }

  const resetForm = () => {
    setFormSlot(defaultSlot)
    setEditingEmployee(defaultEmployee)
  }

  const handleDestroySlot = () => {
    const userConfirmed = window.confirm(t('schedule.delete.confirm'));
    if (!userConfirmed) return;

    deleteSlot(salon, editingEmployee, formSlot)
      .then(response => response.json())
      .then((data) => {
        if (data.errors) {
          toast.error(t('schedule.delete.error', { error: data.errors }))
        } else {
          saveSalonEmployee(data)
          document.getElementById('employee-slot-form').close()
          toast.success(t('schedule.delete.success'))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <dialog id="employee-slot-form" className="modal">
      <div className="modal-box flex flex-col gap-3 overflow-visible">
        <div className='flex justify-between'>
          <h1 className='text-3xl'>{formSlot.week_day_label || t('wholeWeek')}</h1>
          {
            formSlot.id && <div className='btn btn-sm btn-error btn-outline border-none' onClick={handleDestroySlot}>
              <FontAwesomeIcon icon="fa-solid fa-trash" />
            </div>
          }
        </div>
        {
          formSlot.id ? null : (
            <>
              <div className='flex items-center'>
                <h1 className='w-24'>{t('start')}</h1>
                <Select
                  placeholder={t('start')}
                  value={{ value: formSlot.start_time, label: formSlot.start_time }}
                  onChange={handleSlotStartChange}
                  options={availableSlots?.map((slot) => {
                    return {
                      value: slot,
                      label: slot
                    }
                  })}
                  styles={{
                    control: () => {},
                    singleValue: (base) => ({
                      ...base,
                      color: ''
                    })
                  }}
                  classNames={{
                    container: () => 'flex-1',
                    singleValue: () => 'text-warning',
                    control: () =>
                        'input input-bordered flex items-center bg-transparent h-fit',
                  }}
                />
              </div>
              <div className='flex items-center'>
                <h1 className='w-24'>{t('end')}</h1>
                <Select
                  placeholder={t('end')}
                  value={{ value: formSlot.end_time, label: formSlot.end_time }}
                  onChange={handleSlotEndChange}
                  options={availableSlots?.map((slot) => {
                    return {
                      value: slot,
                      label: slot
                    }
                  })}
                  styles={{
                    control: () => {},
                    singleValue: (base) => ({
                      ...base,
                      color: ''
                    })
                  }}
                  classNames={{
                    container: () => 'flex-1',
                    singleValue: () => 'text-warning',
                    control: () =>
                        'input input-bordered flex items-center bg-transparent h-fit',
                  }}
                />
              </div>
            </>
          )
        }
        <div id='slot-products'>
          <h1 className='mb-3'>{t('employees.addProduct')}</h1>
          <div className=''>
            <Select
              placeholder={t('employees.addProduct')}
              noOptionsMessage={() => { return t('employees.noProductsAvailable') }}
              value={{ value: formSalonProduct?.id, label: formSalonProduct.product }}
              onChange={handleSlotProductChange}
              components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
              options={unselectedSalonProducts()?.map((product) => {
                return {
                  value: product.id,
                  label: product.product,
                  mode: product.mode
                }
              })}
              styles={{
                control: () => {},
                singleValue: (base) => ({
                  ...base,
                  color: ''
                })
              }}
              classNames={{
                container: () => 'flex-1',
                singleValue: () => 'text-warning',
                control: () =>
                    'input input-bordered flex items-center bg-transparent h-fit',
              }}
            />
          </div>
          <div id='slot-product-list' className='flex flex-wrap gap-1 mt-2'>
            <h1>
              {t('employees.offeredProductList')}
              {
                formSlot.slot_products.length > 0 ? null : <strong className='text-error ps-3 uppercase'>{t('all')}</strong>
              }
            </h1>
            <div className="overflow-x-auto w-full">
              <table className="table">
                <tbody>
                  {
                    formSlot.slot_products?.map((slot_product) => {
                      const salonProduct = slot_product.salon_product
                      return(
                        <tr key={`${salonProduct.product}`} className='cursor-pointer hover' onClick={() => { handleRemoveSlotProduct(slot_product) }}>
                          <th>{salonProduct.product}</th>
                          <td className='text-end'>{ salonProduct.mode === 'online' ? <span className='badge badge-outline badge-success font-bold'>{t('videoCall')}</span> : null }</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button className="btn btn-success" onClick={handleSubmit}>{t('save')}</button>
      </div>
      <form method="dialog" className="modal-backdrop" onSubmit={resetForm}>
        <button>{t('close')}</button>
      </form>
    </dialog>
  )
}

export default EmployeeSlotForm
