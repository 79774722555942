import { Link } from "react-router-dom";
import { AppStateContext } from "../../contexts/AppStateContext"
import { useContext } from "react"
import { useAuth } from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import Loading from "../utils/loading";

const Sidebar = () => {
  const { salonState, sidebarState } = useContext(AppStateContext);
  const [salon,,] = salonState
  const { isAuthenticated, user } = useAuth();
  const [, setSidebarOpen] = sidebarState;
  const { t } = useTranslation();

  const handleSidebar = () => {
    setSidebarOpen(false)
  }

  return (
    <>
      <ul className="menu p-4 min-h-full bg-base-200">
        {
          isAuthenticated && user ? (
            <>
              <li><Link className='rounded-none' to={'/admin/dashboard'} onClick={handleSidebar}><FontAwesomeIcon className='w-6' icon="fa-regular fa-calendar-days" />{ t('sidebar.appointments') }</Link></li>
              <li><Link className='rounded-none' to={'/admin/dashboard/products'} onClick={handleSidebar}><FontAwesomeIcon className='w-6' icon="fa-solid fa-list-check" />{ t('sidebar.products') }</Link></li>
              <li><Link className='rounded-none' to={'/admin/dashboard/employees'} onClick={handleSidebar}><FontAwesomeIcon className='w-6' icon="fa-solid fa-users" />{ t('sidebar.employees') }</Link></li>
              <li><Link className='rounded-none' to={'/admin/dashboard/reservations_qr'} onClick={handleSidebar}><FontAwesomeIcon className='w-6' icon="fa-solid fa-qrcode" />{ t('sidebar.qr_code') }</Link></li>
              {
                salon ? (
                  salon.online_payments &&
                  <li>
                    <Link className='rounded-none' to={'dashboard/stripe'} onClick={handleSidebar}>
                      <FontAwesomeIcon className='w-6' icon="fa-regular fa-credit-card" />
                      { t('sidebar.payments') }
                      <FontAwesomeIcon className='w-6' icon="fa-brands fa-stripe" size='2xl'/>
                    </Link>
                  </li>
                ) : (
                  <li><div><Loading size="sm"/></div></li>
                )
              }
              <li className="mt-auto mb-4"><Link className='rounded-none' to={'/admin/dashboard/configuration'} onClick={handleSidebar}><FontAwesomeIcon className='w-6' icon="fa-solid fa-gear" />{ t('sidebar.configurations') }</Link></li>
            </>
          ) : (
            <>
              <li><Link className='rounded-none' to={'/'} onClick={handleSidebar}>{ t('sidebar.home') }</Link></li>
              <li><Link className='rounded-none' to={'/admin/sign_in'} onClick={handleSidebar}>{ t('sidebar.sign_in') }</Link></li>
              <li><Link className='rounded-none' to={'/admin/contact'} onClick={handleSidebar}>{ t('sidebar.contact') }</Link></li>
            </>
          )
        }
        {/* <span className="">
          <div className="flex justify-between">
            <ThemeSelect />
            <I18nSelect direction='right' align='middle'/>
          </div>
        </span> */}
      </ul>
    </>
  )
}

export default Sidebar