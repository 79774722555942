import React, { useContext, useEffect } from "react";
import { useParams} from 'react-router-dom';
import { AppStateContext } from "../../../contexts/AppStateContext";
import Header from "./header";
import { toast } from "react-toastify";
import Skeleton from "../../../components/utils/skeleton";
import { useTranslation } from "react-i18next";

export default function Refresh() {
  const { salonState } = useContext(AppStateContext)
  const [salon,,] = salonState
  const {connectedAccountId} = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (connectedAccountId) {
      fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/stripe/account/link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify({
          account: connectedAccountId,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          const { url, error } = json;

          if (url) {
            window.location.href = url;
          }

          if (error) {
            toast.error(error)
          }
        });
    }
  }, [connectedAccountId, salon?.id])

  return (
    <div className="container">
      <Header />
      <div className="flex flex-col items-center mt-5">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-xl font-bold">{ t('stripe.account_status') }</h2>
          <button className='btn btn-primary min-w-44'>
            { t('stripe.refresh') } <span className="loading loading-spinner text-warning"></span>
          </button>
        </div>
        <Skeleton />
      </div>
    </div>
  );
}
