import React, { useContext } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { generateTimeSlots } from '../../../lib/date';
import { AppStateContext } from '../../../contexts/AppStateContext';
import { addSlot } from '../../../lib/api_service/salons';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const animatedComponents = makeAnimated();

const SalonSlotForm = () => {
  const { t } = useTranslation();
  const defaultSlot = {
    week_day: 0,
    start_time: '',
    end_time: '',
    week_day_label: ''
  }
  const { salonState, formSlotState, salonFormState } = useContext(AppStateContext);
  const [salon,setSalon,] = salonState;
  const [formSlot, setFormSlot] = formSlotState
  const [,,saveSalon] = salonFormState;
  const availableSlots = generateTimeSlots()

  const handleSlotStartChange = (time) => {
    setFormSlot({
      ...formSlot,
      start_time: time.value
    })
  }

  const handleSlotEndChange = (time) => {
    setFormSlot({
      ...formSlot,
      end_time: time.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addSlot(salon, formSlot)
      .then(response => response.json())
      .then((data) => {
        if (data.errors) {
          toast.error(`Error guardando horario: ${data.errors}`)
        } else {
          setSalon(data)
          saveSalon(data)
          toast.success('horario guardado')
          resetForm()
        }
      })
    document.getElementById('salon-slot-form').close()
  }

  const resetForm = () => {
    setFormSlot(defaultSlot)
  }

  return (
    <dialog id="salon-slot-form" className="modal">
      <div className="modal-box flex flex-col gap-3 overflow-visible">
        <h1 className='text-3xl'>{formSlot.week_day_label || 'Toda la semana'}</h1>
        <div className='flex items-center'>
          <h1 className='w-24'>{t('start')}</h1>
          <Select
            placeholder="Inicio"
            value={{ value: formSlot.start_time, label: formSlot.start_time } || []}
            onChange={handleSlotStartChange}
            components={animatedComponents}
            options={availableSlots?.map((slot) => {
              return {
                value: slot,
                label: slot
              }
            })}
            styles={{
              control: () => {},
              singleValue: (base) => ({
                ...base,
                color: ''
              })
            }}
            classNames={{
              container: () => 'flex-1',
              singleValue: () => 'text-warning',
              control: () =>
                  'input input-bordered flex items-center bg-transparent h-fit',
            }}
          />
        </div>
        <div className='flex items-center'>
          <h1 className='w-24'>Fin</h1>
          <Select
            placeholder="Inicio"
            value={{ value: formSlot.end_time, label: formSlot.end_time } || []}
            onChange={handleSlotEndChange}
            components={animatedComponents}
            options={availableSlots?.map((slot) => {
              return {
                value: slot,
                label: slot
              }
            })}
            styles={{
              control: () => {},
              singleValue: (base) => ({
                ...base,
                color: ''
              })
            }}
            classNames={{
              container: () => 'flex-1',
              singleValue: () => 'text-warning',
              control: () =>
                  'input input-bordered flex items-center bg-transparent h-fit',
            }}
          />
        </div>
        <button className="btn btn-primary" onClick={handleSubmit} >Guardar</button>
      </div>
      <form method="dialog" className="modal-backdrop" onSubmit={resetForm}>
        <button>close</button>
      </form>
    </dialog>
  )
}

export default SalonSlotForm