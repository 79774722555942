import React from 'react';
import { useTranslation } from 'react-i18next';
import I18nSelect from '../components/utils/i18n_select';

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12">
      <div className="relative w-full max-w-4xl mx-auto bg-white p-8 shadow-md rounded-lg">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">{t('terms.title')}</h1>
        <div className="absolute top-0 end-0">
          <I18nSelect />
        </div>
        <p className="text-gray-600 mb-4">{t('terms.intro')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('terms.services.title')}</h2>
        <p className="text-gray-600 mb-4">{t('terms.services.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('terms.responsibilities.title')}</h2>
        <p className="text-gray-600 mb-4">{t('terms.responsibilities.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('terms.limitation.title')}</h2>
        <p className="text-gray-600 mb-4">{t('terms.limitation.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('terms.changes.title')}</h2>
        <p className="text-gray-600 mb-4">{t('terms.changes.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('terms.contact.title')}</h2>
        <p className="text-gray-600 mb-4">{t('terms.contact.description')}</p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
