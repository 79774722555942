import { AppStateContext } from "../../contexts/AppStateContext"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import I18nSelect from "../utils/i18n_select";
import ThemeSelect from "../utils/theme_select";

const Navbar = ({ lng, landing }) => {
  const { sidebarState, salonState } = useContext(AppStateContext);
  const [salon,,] = salonState;
  const [, setSidebarOpen] = sidebarState;
  const { isAuthenticated, logout, user } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={`w-full navbar z-10 ${landing ? 'sticky top-0 bg-base-200' : 'bg-base-300'}`}>
      <div className="flex-none lg:ms-6">
        {
          !landing && <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost lg:hidden" onClick={() => { setSidebarOpen(true) }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
          </label>
        }
        <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Icon" className="inline-block w-12 h-12"/>
      </div>
      <button className="mx-2" onClick={() => { navigate(isAuthenticated ? '/admin/dashboard' : '') }}>
        {
          isAuthenticated && salon ? (
            salon.name
          ) : (
            <div className="flex flex-col">
              <span className="text-sm self-end">Velvet</span>
              <span className="text-sm self-start">Booking</span>
            </div>
          )
        }
      </button>
      <div className="flex-1"></div>
      <ThemeSelect  />
      <I18nSelect />
      {
        isAuthenticated && user ? (
          <div className="dropdown dropdown-end z-50">
            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <FontAwesomeIcon icon={['fas', 'user']} size='3x' />
              </div>
            </div>
            <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
              <li><button onClick={() => { navigate('/admin/dashboard/profile')}}>{t('navbar.profile')}</button></li>
              <li><button onClick={() => { navigate('/admin/dashboard/configuration')}}>{t('navbar.configuration')}</button></li>
              <li><button onClick={() => { navigate('/admin/dashboard/switch')}}>{t('navbar.switch')}</button></li>
              <li><button onClick={() => { navigate('/')}}>{t('navbar.home')}</button></li>
              <li>
                <Link onClick={logout}>{t('navbar.logout')}</Link>
              </li>
            </ul>
          </div>
        ) : (
          <Link to={'/admin/sign_in'} className="text-right w-fit md:mr-5">
            <p>Iniciar sesión</p>
          </Link>
        )
      }
    </div>
  )
}

export default Navbar