import React, { useContext } from 'react';
import { CalendarContext } from '../../contexts/CalendarContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { delayAppointment, deleteAppointment } from '../../lib/api_service/appointments';
import { AppStateContext } from '../../contexts/AppStateContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const List = () => {
  const { t } = useTranslation();
  const { appointments, setAppointments, selectedDate, selectedEmployee } = useContext(CalendarContext);
  const { salonState } = useContext(AppStateContext);
  const [salon,,] = salonState;

  const isToday = () => {
    return moment(selectedDate).isSame(new Date(), 'day');
  };

  const appointmentsToday = () => {
    const startOfDay = moment(selectedDate).startOf('day');
    const endOfDay = moment(selectedDate).endOf('day');
    const filteredAppointments = Object.values(appointments).filter(appointment => {
      const start = moment(appointment?.start_date);
      const end = moment(appointment?.end_date);
      const assignedToSelectedEmployee = !selectedEmployee.id || (appointment.employee?.id === selectedEmployee.id)
      return (start.isBetween(startOfDay, endOfDay) || end.isBetween(startOfDay, endOfDay)) && assignedToSelectedEmployee;
    });
    const sortedAppointments = filteredAppointments.sort((a, b) => {
      return moment(a.start_date) - moment(b.start_date);
    });
    return sortedAppointments;
  };

  const contactInfo = (appointment) => {
    const phone = appointment?.customer.phone_number;
    const email = appointment?.customer.email;
    const separator = phone && email ? ' - ' : '';
    return `${phone}${separator}${email}`;
  };

  // const handleEdit = (emp) => {
  //   setEditingAppointment(emp);
  //   document.getElementById('appointment-form').showModal();
  // };

  const handleDestroy = (appointment) => {
    const userConfirmed = window.confirm(t('appointments.delete_confirmation'));
    if (!userConfirmed) return;

    deleteAppointment(salon, appointment)
      .then((response) => {
        if (response.ok) {
          delete appointments[appointment.id];
          setAppointments({ ...appointments });
        } else {
          response.json().then((data) => {
            console.error(data);
          });
        }
      }).catch((error) => {
        console.error(error);
      });
  };

  // const handleTooltipClick = (data) => {
  //   try {
  //     navigator.clipboard.writeText(data);
  //     toast.success(t('appointments.uuid_copied'));
  //   } catch (error) {
  //     console.error('Failed to copy: ', error);
  //     toast.error(t('appointments.copy_failed'));
  //   }
  // };

  const handleDelay = (appointment) => {
    const userConfirmed = window.confirm(t('appointments.delay_confirmation'));
    if (!userConfirmed) return;

    delayAppointment(salon, appointment)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            toast.success(data.message);
          });
        } else {
          response.json().then((data) => {
            toast.error(data.message);
          });
        }
      }).catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="join-item pt-2 grid gap-3">
      {
        appointmentsToday().length > 0 ? appointmentsToday()?.map(appointment => {
          const noContactInfo = !appointment?.phone_number && !appointment?.email;
          return (
            <div key={appointment?.id} className="card bg-base-100 shadow-xl">
              <div className="card-body p-3">
                <div className="card-title flex">
                  <h1 className='text-3xl flex-1 hyphens-auto'>{ appointment.customer ? `${appointment.customer.first_name} ${appointment.customer.last_name}` : t('busy') }</h1>
                  {
                    appointment.appointment_type === 'default' && <>
                      <button className="btn btn-sm btn-outline" onClick={() => { handleDelay(appointment); }}>
                        <div className='flex capitalize'>
                          {t('delay')}
                        </div>
                      </button>
                    </>
                  }
                  <button className="btn btn-sm btn-outline btn-error" onClick={() => { handleDestroy(appointment); }}>
                    {t('cancel')}
                  </button>
                </div>
                {
                  appointment.appointment_type === 'default' ? <h1 className='text-sm'>
                    {noContactInfo ? t('appointments.no_contact_info') : contactInfo(appointment)}
                  </h1> : (appointment.description && <div className='mb-3'>{appointment.description}</div>)
                }
                <div className='flex justify-between'>
                  <p>{moment(appointment?.start_date).format('HH:mm a')} - {moment(appointment?.end_date).format('HH:mm a')}</p>
                  <div>
                    {
                      appointment.payment_method && <div
                        className="tooltip"
                        data-tip={t(`paymentMethods.${appointment.payment_method.name}`)}
                      >
                        <FontAwesomeIcon icon={t(`paymentMethods.icons.${appointment.payment_method.name}`)} />
                      </div>
                    }
                    {
                      appointment.appointment_type === 'default' && <>
                        <strong className='mx-2'>{t('paid')}:</strong>
                        { appointment.paid ? t('yes') : t('no')}
                      </>
                    }
                  </div>
                </div>
                <div className='flex justify-between items-center'>
                  <div className="flex-1 flex gap-2 flex-wrap">
                    {
                      appointment?.salon_products?.map(salon_product => {
                        return <div key={salon_product.id} className="badge badge-info">{salon_product.product}</div>
                      })
                    }
                  </div>
                  {
                    appointment.google_meet_url && <Link to={appointment.google_meet_url} target='_blank' title='google meet link' className='flex items-center gap-2'>
                      <img src={`${process.env.PUBLIC_URL}/google_meet_logo.png`} width='30' alt='' />
                    </Link>
                  }
                </div>
              </div>
            </div>
          );
        }) : (
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <h1 className="card-title">{t('appointments.no_appointments', { date: isToday() ? t('today') : moment(selectedDate).locale('es').format('LL') })}</h1>
              <p>{t('appointments.no_appointments_message')}</p>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default List;
