export const DayHeader = ({ day, wDay }) => {
  return (
    <div className="day-header text-sm lg:text-lg flex flex-col justify-center text-start">
      <span className="text-sm text-warning">
        {wDay}
      </span>
      <span className="text-3xl text-warning">
        {day.format('DD')}
      </span>
    </div>
  );
}
