import React, { useContext, useState } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppStateContext } from '../../contexts/AppStateContext';
import { deletePto } from '../../lib/api_service/employees';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Employee = ({ salonEmployee, handleEdit, handleDestroy }) => {
  const { salonState, formSlotState, employeeFormState } = useContext(AppStateContext);
  const [salon,,] = salonState;
  const [, setEditingEmployee,,saveSalonEmployee] = employeeFormState;
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [ptosVisible, setPtosVisible] = useState(false);
  const [,,formSlot, setFormSlot] = formSlotState;
  const { t } = useTranslation();
  const weekDays = t('dates.weekDays.long', { returnObjects: true });

  const handleNewSlotForm = (weekDay, weekDayLabel) => {
    setFormSlot({
      ...formSlot,
      week_day: weekDay,
      week_day_label: weekDayLabel
    });
    setEditingEmployee(salonEmployee);
    document.getElementById('employee-slot-form').showModal();
  };

  const handleEditSlotForm = (slot) => {
    setFormSlot({
      ...slot,
      week_day_label: weekDays[slot.week_day]
    });
    setEditingEmployee(salonEmployee);
    document.getElementById('employee-slot-form').showModal();
  };

  const handlePtoForm = () => {
    setEditingEmployee(salonEmployee);
    document.getElementById('employee-pto-form').showModal();
  };

  const handleDestroyPto = (pto) => {
    const userConfirmed = window.confirm(t('ptos.delete_confirmation'));
    if (!userConfirmed) return;

    deletePto(salon, salonEmployee, pto)
      .then(response => response.json())
      .then((data) => {
        if (data.errors) {
          toast.error(t('ptos.delete_error', { error: data.errors }));
        } else {
          saveSalonEmployee(data);
          toast.success(t('ptos.deleted_success'));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div id={`employee-${salonEmployee.id}`} key={`employee-${salonEmployee.id}`} className="card card-side join join-vertical flex flex-col bg-base-100 shadow-xl">
      <div className="card-body p-3 lg:p-5">
        <div className="flex-1 flex flex-col gap-1">
          <div className="card-title flex">
            <span className='flex-1'>{salonEmployee?.user.full_name}</span>
            <div className="card-actions justify-end">
              <button className="btn btn-outline border-none" onClick={() => { handleEdit(salonEmployee); }}>
                <FontAwesomeIcon icon="fa-solid fa-edit" />
              </button>
              <button className="btn btn-outline btn-error border-none" onClick={() => { handleDestroy(salonEmployee); }}>
                <FontAwesomeIcon icon="fa-solid fa-trash" />
              </button>
            </div>
          </div>
          <span>{salonEmployee.user.email}</span>
          <span>{formatPhoneNumberIntl(salonEmployee.user.phone_number)}</span>
          <div className="flex flex-wrap gap-2">
            {
              salonEmployee?.salon_products?.length > 0 ? (
                salonEmployee.salon_products.map((salon_product) => {
                  return (
                    <div
                      key={`employee-${salonEmployee.id}-product-${salon_product.id}`}
                      className='badge badge-info'
                    >
                      <span>
                        {salon_product.product}
                      </span>
                      {
                        salon_product.mode === 'online' ? <>
                          <span className='mx-1'>-</span>
                          <FontAwesomeIcon icon="fa-solid fa-video" />
                        </> : null
                      }
                    </div>
                  );
                })
              ) : (
                <div className='badge badge-warning'>{t('employees.noProductsAvailable')}</div>
              )
            }
          </div>
        </div>
      </div>
      <button className='btn text-center font-bold cursor-pointer join-item' onClick={() => setCalendarVisible(!calendarVisible)}>
        { calendarVisible ? t('configurations.hide_schedule') : t('configurations.configure_schedule') }
        <FontAwesomeIcon icon="fa-solid fa-calendar-days" className='ms-1'/>
        { calendarVisible ?
          <FontAwesomeIcon icon="fa-solid fa-chevron-up" className='ms-3'/>
          : <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='ms-3'/>
        }
      </button>
      { calendarVisible &&
        <div className='flex p-3 lg:ps-5'>
          <button className='btn btn-success h-full me-5 px-1 py-2' >
            <span style={{ writingMode: 'vertical-rl' }} onClick={() => { handleNewSlotForm() }}>+ {t('week')}</span>
          </button>
          <div id={`employee-${salonEmployee.id}-calendar`} className='flex-1 grid grid-rows-4 grid-flow-col lg:grid-rows-1 md:grid-rows-3 gap-4'>
            {
              weekDays.map((day, index) => {
                const key = `employee-${salonEmployee.id}-weekday-${index}`;
                const schedule = salonEmployee.schedule;
                const slots_for_day = schedule.slots.filter((slot) => slot.week_day === index);
                return (
                  <div id={key} key={key} className='flex-1 flex flex-col gap-1'>
                    <div className="flex items-center gap-2 mb-2">
                      <button className='btn btn-xs btn-success' onClick={() => { handleNewSlotForm(index, day) }}>+</button>
                      <span key={`employee-${salonEmployee.id}-weekday-${index}-day-${day}`} className='inline-block align-middle'>{day}</span>
                    </div>
                    <div className='grid lg:grid-cols-1 gap-1'>
                      {
                        slots_for_day.length > 0 ? (
                          slots_for_day?.map((slot, slotIndex) => {
                            const slotProducts = slot.slot_products;
                            return (
                              <div
                                id={`employee-${salonEmployee.id}-weekday-${index}-slot-${slotIndex}`}
                                key={`employee-${salonEmployee.id}-weekday-${index}-slot-${slotIndex}`}
                                className="tooltip tooltip-primary text-start"
                                data-tip={
                                  slotProducts.length > 0 ?
                                    slotProducts.map((sp) => sp.salon_product.mode === 'online' ? `${sp.salon_product.product} ( ${sp.salon_product.mode} )` : sp.salon_product.product).join(' / ')
                                    : null
                                }
                              >
                                <div
                                  className='badge badge-outline badge-info cursor-copy gap-1'
                                  onClick={() => { handleEditSlotForm(slot) }}
                                >
                                  {slot.start_time} - {slot.end_time}
                                  <div className='font-bold text-warning'>
                                    { slotProducts.length > 0 ? `/ ${slotProducts.length}` : null }
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className='badge badge-info capitalize'>{t('schedule.free')}</div>
                        )
                      }
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      }
      <button className='btn text-center font-bold cursor-pointer mt-3 join-item' onClick={() => setPtosVisible(!ptosVisible)}>
        { ptosVisible ? t('ptos.hide') : t('ptos.configure') }
        <FontAwesomeIcon icon="fa-solid fa-calendar-days" className='ms-1'/>
        { ptosVisible ?
          <FontAwesomeIcon icon="fa-solid fa-chevron-up" className='ms-3'/>
          : <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='ms-3'/>
        }
      </button>
      { ptosVisible &&
        <div className='flex p-3 gap-2'>
          <button className='btn btn-sm btn-success' onClick={() => { handlePtoForm() }}>+</button>
          <div className='flex flex-wrap gap-2 items-center'>
            {
              salonEmployee?.ptos?.length > 0 ? (
                salonEmployee.ptos.map((pto, ptoIndex) => {
                  const key = `employee-${salonEmployee.id}-pto-${ptoIndex}`;
                  const startMoment = moment(pto.start_date).format('DD/MM/YYYY');
                  const endMoment = moment(pto.end_date).format('DD/MM/YYYY');
                  return (
                    <div key={key} id={key} className='badge badge-outline badge-secondary cursor-pointer' onClick={() => { handleDestroyPto(pto) }}>
                      {startMoment} - {endMoment}
                    </div>
                  );
                })
              ) : (
                <div className='badge badge-info'>{t('ptos.none')}</div>
              )
            }
          </div>
        </div>
      }
    </div>
  );
}

export default Employee;
