import React, { useContext } from 'react';
import { AppStateContext } from '../../contexts/AppStateContext';
import EmployeeForm from '../../components/employees/form';
import { deleteEmployee } from '../../lib/api_service/employees';
import Employee from '../../components/employees/employee';
import EmployeeSlotForm from '../../components/employees/slots/form';
import EmployeePtoForm from '../../components/employees/ptos/form';
import { useTranslation } from 'react-i18next';

function Employees() {
  const { salonState, employeesState, employeeFormState } = useContext(AppStateContext);
  const [salon,,] = salonState;
  const [employees, setEmployees] = employeesState;
  const [, setEditingEmployee,,] = employeeFormState;
  const { t } = useTranslation();

  const handleEdit = (emp) => {
    setEditingEmployee(emp);
    document.getElementById('employee-form').showModal();
  };

  const handleCreate = () => {
    setEditingEmployee({});
    document.getElementById('employee-form').showModal();
  };

  const handleDestroy = (employee) => {
    const userConfirmed = window.confirm(t('employees.delete_confirmation'));
    if (!userConfirmed) return;

    deleteEmployee(salon, employee)
      .then((response) => {
        if (response.ok) {
          delete employees[employee.id];
          setEmployees({ ...employees });
        } else {
          response.json().then((data) => {
            console.error(data);
          });
        }
      }).catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <button className='btn btn-success w-full mb-2' onClick={handleCreate}>{t('employees.new')}</button>
      <div className='flex flex-col gap-2 max-h-[calc(100vh-9rem)] overflow-y-auto' id='employees-list'>
        {
          Object.values(employees)?.length > 0 ? Object.values(employees).map((salonEmployee) => {
            return (
              <Employee
                key={salonEmployee.id}
                salonEmployee={salonEmployee}
                handleEdit={handleEdit}
                handleDestroy={handleDestroy}
              />
            );
          }) : null
        }
      </div>
      <EmployeeForm />
      <EmployeeSlotForm />
      <EmployeePtoForm />
    </>
  );
}

export default Employees;
