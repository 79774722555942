import React, { useContext } from 'react';
import Select from 'react-select';
import { formatValue } from 'react-currency-input-field';
import { IntakeContext } from '../../../contexts/IntakeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { intlConfig } from '../../../components/utils';
import makeAnimated from 'react-select/animated';
import { CustomOption } from '../../../components/utils/select';
import { useTranslation } from 'react-i18next';

const animatedComponents = makeAnimated();

const ProductSelection = () => {
  const { locale, salon, appointment, setAppointment, setSelectedSlot, maxDuration, setMaxDuration, totalPrice, setTotalPrice, setValidPaymentMethods, prepaymentSelected, setPrepaymentSelected } = useContext(IntakeContext)
  const { t } = useTranslation();

  const handleProductsChange = (products) => {
    const productsSelected = salon.salon_products?.filter((product) => {
      return products?.some((selectedProduct) => {
        return product.id === selectedProduct.value
      })
    });
    const prepaymentRequired = productsSelected.some(p => p.prepayment_required)
    setPrepaymentSelected(prepaymentRequired)
    let commonPaymentMethods = productsSelected.reduce((commonMethods, product) => {
      return commonMethods.filter(method => product?.payment_methods?.some(pm => pm.id === method.id));
    }, productsSelected[0]?.payment_methods) || []
    commonPaymentMethods = prepaymentRequired ? commonPaymentMethods.filter(pm => pm.name === 'online') : commonPaymentMethods
    setValidPaymentMethods(commonPaymentMethods)
    setAppointment((prev) => {
      return {
        ...prev,
        salon_products: productsSelected,
        payment_method: commonPaymentMethods[0]
      }
    })
    setSelectedSlot(null)
    setMaxDuration(
      productsSelected.reduce((total, product) => {
        return total + product.duration;
      }, 0)
    )
    setTotalPrice(
      productsSelected.reduce((total, product) => {
        return total + product.price_cents;
      }, 0) / 100.0
    )
  }

  return (
    <>
      <Select
        placeholder={t('intake.inputs.products.placeholder')}
        isMulti
        isSearchable={false}
        value={appointment.salon_products.map((salon_product) => {
          const free = salon_product.price_cents === 0
          const formattedPrice = formatValue({ value: String(salon_product.price_cents / 100.0), intlConfig: intlConfig(locale) })
          return { value: salon_product.id, label: free ? salon_product.product : `${salon_product.product} - ${formattedPrice}` }
        })}
        onChange={handleProductsChange}
        components={{...animatedComponents, Option: CustomOption }}
        options={salon.salon_products?.map((salon_product) => {
          const free = salon_product.price_cents === 0
          const formattedPrice = formatValue({ value: String(salon_product.price_cents / 100.0), intlConfig: intlConfig(locale) })
          return {
            value: salon_product.id,
            label: free ? salon_product.product : `${salon_product.product} - ${formattedPrice}`,
            mode: salon_product.mode,
            free: free,
            prepayment_required: salon_product.prepayment_required,
            online_payments: salon_product.online_payments
          }
        })}
        styles={{
          multiValue: () => {},
          control: () => {},
        }}
        classNames={{
          multiValue: () => 'badge badge-info',
          valueContainer: () => 'flex gap-x-2',
          control: () =>
              'input shadow-md shadow-gray-200 bg-gray-50 flex items-center h-fit',
        }}
      />
      { appointment.salon_products.length > 0 && <div className="stats shadow-md shadow-gray-200 bg-gray-50 text-black">
        <div className="stat">
          <div className="stat-figure text-primary text-4xl">
            <FontAwesomeIcon icon={'far fa-clock'}/>
          </div>
          <div className="stat-title text-black">{t('intake.stats.duration.title')}</div>
          <div className="stat-value text-primary">{maxDuration}</div>
          <div className="stat-desc text-black">{t('intake.stats.duration.unit')}</div>
        </div>
        <div className="stat">
          <div className="stat-figure text-info text-4xl">
            { prepaymentSelected ? <FontAwesomeIcon icon={'fas fa-credit-card'}/> : <FontAwesomeIcon icon={'fas fa-coins'}/> }
          </div>
          <div className="stat-title text-black">{t('intake.stats.total_price.title')}</div>
          <div className="stat-value text-info">{ totalPrice === 0 ? t('free') : formatValue({ value: String(totalPrice), intlConfig: intlConfig(locale) }) }</div>
        </div>
      </div>
      }
      {
        prepaymentSelected && <div className="text-wrap text-end">
          <FontAwesomeIcon icon="fa-solid fa-hand-holding-dollar" className='me-2'/>
          <span>{t('intake.checkout.prepayment_required')}</span>
        </div>
      }
    </>
  );
};

export default ProductSelection;
