import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ConfirmCancellation = () => {
  let { appointmentUUID } = useParams();
  const navigate = useNavigate();
  const [cancelling, setCancelling] = useState(false);

  async function handleCancel() {
    const userConfirmed = window.confirm('Aceptar para cancelar la cita');
    if (!userConfirmed) return;

    if (cancelling) return;

    setCancelling(true);

    fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/citas/cancelar/${appointmentUUID}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          setCancelling(false);
          navigate('/cancelacion-exitosa')
        } else {
          response.json().then((data) => {
            console.error(data)
            setCancelling(false);
            toast.error(data.errors[0])
          })
        }
      })
  }

  return (
    <div className='container mx-auto p-4'>
      <h1 className='text-center text-warning text-3xl'>Cancelar cita</h1>
      <p className='text-center'>Identificador:</p>
      <p className='text-center'>{appointmentUUID}</p>
      <p className='my-10 text-xl text-center text-warning'>
        ¿Está seguro/a que desea cancelar esta cita?
      </p>
      <div className='flex justify-around'>
        {
          cancelling ? (
            <span className="loading loading-lg loading-spinner text-error mx-auto"></span>
          ) : (
            <button className='btn btn-error' onClick={handleCancel}>Cancelar</button>
          )
        }
      </div>
    </div>
  )
}

export default ConfirmCancellation