import React from 'react'
import { useAuth } from '../hooks/useAuth';

const AdminLanding = () => {
  const { isAuthenticated, user } = useAuth();
  return (
    <div className='container mx-auto mt-10'>
      {
        isAuthenticated && user ? (
          <>
            <h1 className='text-2xl'>Bienvenido <strong className='text-3xl italic text-primary'>{user.full_name}</strong> a tu sistema de reserva de citas</h1>
            <p className='text-warning'>
              Administre su negocio de manera eficiente
            </p>
          </>
        ) : (
          <>
            <h1 className='text-2xl'>Bienvenido a nuestro sistema de reserva de citas</h1>
            <p className='text-warning'>
              Por favor iniciar sesión para continuar
            </p>
          </>
        )
      }
    </div>
  )
}

export default AdminLanding