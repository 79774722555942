import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { AppStateContext } from '../../contexts/AppStateContext';

const EmployeeCalendar = () => {
  const { employeeId } = useParams();
  const { employeesState } = useContext(AppStateContext)
  const [employees,] = employeesState
  const [employee, setEmployee] = useState({})

  useEffect(() => {
    setEmployee(employees[Number(employeeId)])
  }, [employees, employeeId])

  return (
    <div>
      {JSON.stringify(employee)}
    </div>
  )
}

export default EmployeeCalendar