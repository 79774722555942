import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppStateContextProvider from './contexts/AppStateContext'
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './hooks/useAuth';
import ErrorBoundary from './routes/error_boundary';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AuthProvider>
        <AppStateContextProvider>
          <App />
        </AppStateContextProvider>
      </AuthProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
