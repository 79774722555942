export async function updateSalon(salonData) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salonData.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      salon: salonData
    })
  })
}

export async function addSlot(salon, slot) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/add_slot`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        slot: {
          week_day: slot.week_day,
          start_time: slot.start_time,
          end_time: slot.end_time
        }
      }
    )
  })
}

export async function deleteSlot(salon, slot) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/remove_slot/${slot.id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}