import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createContact } from '../../lib/api_service/contact';
import { useNavigate } from 'react-router-dom';

const contactInfo = {
  email: 'info@velvet-booking.com',
  phone: '+34681080159'
}

const ContactForm = ({ redirect = true }) => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true)
    createContact(formData)
      .then(response => response.json())
      .then((data) => {
        setSubmitting(false)
        if (data.errors) {
          toast.error('Error al enviar datos de contact, pruebe dentro de unos minutos')
        } else {
          setSubmitted(true)
          toast.success('Datos de contacto enviado!')
          redirect && navigate('/contacto_recibido')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  };

  const { t } = useTranslation();

  const copyToClipboard = async (name, text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(t('copy.success', { name: name }));
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast.error(t('copy.failure', { name: name }));
    }
  };

  return (
    <div className="bg-base-200 max-w-md mx-auto my-10 p-6 shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">{t('contactUs')}</h2>
      <div className="flex items-center mb-3">
        <a href={`mailto:${contactInfo.email}`} className="text-gray-600">
          <FontAwesomeIcon icon={'fas fa-envelope'} className='me-3'/>
          {contactInfo.email}
        </a>
        <FontAwesomeIcon icon={'fas fa-copy'} className='ms-auto cursor-pointer' onClick={() => { copyToClipboard(t('email'), contactInfo.email) }} />
      </div>
      <div className="flex items-center">
        <a href={`tel:${contactInfo.phone}`} className="text-gray-600">
          <FontAwesomeIcon icon={'fas fa-phone'} className='me-3'/>
          {contactInfo.phone}
        </a>
        <FontAwesomeIcon icon={'fas fa-copy'} className='ms-auto cursor-pointer' onClick={() => { copyToClipboard(t('phone'), contactInfo.phone) }} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-control mb-4">
          <label htmlFor="name" className="label">
            <span className="label-text text-gray-600">{t('name')}</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input input-bordered w-full bg-white"
            required
          />
        </div>
        <div className="form-control mb-4">
          <label htmlFor="email" className="label">
            <span className="label-text text-gray-600">{t('email')}</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="input input-bordered w-full bg-white"
            required
          />
        </div>
        <div className="form-control mb-4">
          <label htmlFor="message" className="label">
            <span className="label-text text-gray-600">{t('message')}</span>
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="textarea textarea-bordered w-full bg-white"
            rows="5"
            required
          ></textarea>
        </div>
        <div className="text-center">
          {
            submitting ? (
              <div className="btn btn-primary w-full btn-disabled">
                <span className="loading loading-spinner text-black"></span>
              </div>
            ) : (
              submitted ? (
                <div className="btn btn-disabled w-full">
                  <span className='text-black'>{t('weWillRespond')}</span>
                </div>
              ) : (
                <button type="submit" className="btn btn-primary w-full">
                  {t('submit')}
                </button>
              )
            )
          }
        </div>
      </form>
    </div>
  );
};

export default ContactForm;