import React, { useContext } from "react";
import Header from "./header";
import { AppStateContext } from "../../../contexts/AppStateContext";
import Onboarding from "./onboarding";
import Status from "./status";

export default function StripeDashboard() {
  const { salonState } = useContext(AppStateContext)
  const [salon,,] = salonState

  return (
    <div className="container">
      <Header />
      { salon.stripe_account_id ? <Status /> : <Onboarding /> }
    </div>
  );
}