import React, { useContext, useState } from 'react'
import { AppStateContext } from '../../../contexts/AppStateContext';
import { toast } from 'react-toastify';
import { accountCreate } from '../../../lib/api_service/stripe';
import { useTranslation } from 'react-i18next';

const Onboarding = () => {
  const { t } = useTranslation();
  const { salonState } = useContext(AppStateContext)
  const [salon,,saveSalon] = salonState
  const [accountCreatePending, setAccountCreatePending] = useState(false);

  return (
    <div className="container flex flex-col items-center">
      <p className='mt-5'>{t('stripe.onboarding.must_create_account')}</p>
      <button
        className='btn btn-primary mt-5'
        onClick={async () => {
          setAccountCreatePending(true);
          accountCreate(salon)
            .then((response) => response.json())
            .then((json) => {
              setAccountCreatePending(false);

              const { salon, error } = json;

              if (salon) {
                saveSalon(salon)
              }

              if (error) {
                toast.error(t('toast.error'))
                console.error(error)
              }
            });
        }}
      >
        { accountCreatePending ? t('stripe.onboarding.creating_account') : t('stripe.onboarding.create_account') }
      </button>
    </div>
  );
}

export default Onboarding