import { isValidPhoneNumber } from 'react-phone-number-input';

export const isValidEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && emailRegex.test(String(email).toLowerCase());
};

export const isValidPhone = (phoneNumber) => {
  return phoneNumber === '' || isValidPhoneNumber(phoneNumber);
};

export const validContact = (customer) => {
  return isValidEmail(customer.email) && isValidPhone(customer.phone_number)
}

export const intlConfig = (locale) => {
  return {
    locale: locale?.languages?.split(',')[0],
    currency: locale?.currency
  };
};

export const getRandomLightColor = () => {
  const base = 200;
  const darkenFactor = 20;
  const red = base + Math.floor(Math.random() * (255 - base));
  const green = base + Math.floor(Math.random() * (255 - base));
  const blue = base + Math.floor(Math.random() * (255 - base));
  const darkerRed = Math.max(0, red - darkenFactor);
  const darkerGreen = Math.max(0, green - darkenFactor);
  const darkerBlue = Math.max(0, blue - darkenFactor);

  return {
    backgroundColor: `rgb(${red}, ${green}, ${blue}, 1)`,
    borderColor: `rgb(${darkerRed}, ${darkerGreen}, ${darkerBlue}, 1)`,
  };
}