import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Import translation files
import translationES from './locales/es.json';
import translationEN from './locales/en.json';

const resources = {
  'es-ES': {
    translation: translationES,
  },
  'en-US': {
    translation: translationEN,
  },
  'en-GB': {
    translation: translationEN,
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'es-ES',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;