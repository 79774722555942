import moment from 'moment';
import { useContext } from 'react';
import { CalendarContext } from '../../../../contexts/CalendarContext';
import { useTranslation } from 'react-i18next';

const Appointment = ({ appointment, height, over, under, entireDay }) => {
  const { t } = useTranslation();
  const { setSelectedAppointment } = useContext(CalendarContext);

  const startDateMoment = moment(appointment.start_date);
  const endDateMoment = moment(appointment.end_date);

  const startMinutes = startDateMoment.minutes();
  const endMinutes = endDateMoment.minutes();
  const duration = endDateMoment.diff(startDateMoment, 'minutes');

  const top = (startMinutes / 60) * 100;
  const bottom = ((60 - endMinutes) / 60) * 100;

  const body = `${startDateMoment.format('HH:mm')} - ${endDateMoment.format('HH:mm')}`;
  const totalWidth = 90;

  const width = appointment.overlap === undefined ? `${totalWidth}%` : `${totalWidth / appointment.overlap}%`
  const position = appointment.position - 1
  const left = appointment.overlap === undefined ? null : `calc(${(totalWidth / appointment.overlap) * position}%)`

  const appointmentStyle = {
    top: over || entireDay ? null : `${top}%`,
    bottom: over ? `${bottom}%` : null,
    height: (under || over || entireDay) ? height : `${duration}px`,
    width: width,
    left: left,
    zIndex: 1,
    cursor: 'pointer',
    ...appointment.color_style
  }

  const handleAppointmentClick = (event) => {
    event.stopPropagation();

    document.getElementById('appointment-modal').showModal()
    setSelectedAppointment(appointment)
  }

  return (
    <div
      className="absolute left-0 appointment rounded border-2 flex flex-col"
      style={appointmentStyle}
      onClick={handleAppointmentClick}
    >
      <h3 className='truncate capitalize'>
        { appointment.customer ? `${appointment.customer.first_name} ${appointment.customer.last_name}` : t('busy') }
      </h3>
      <span className='flex-1'>
        {body}
      </span>
      <span>{appointment.employee?.first_name}</span>
    </div>
  );
};


export default Appointment;