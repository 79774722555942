import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <div className='container flex flex-col'>
      <h1 className='text-3xl mt-5'>{t('profile.greeting', { name: user.full_name })}</h1>
      <span>{t('profile.email')}: {user.email}</span>
      <Link to={'/password/reset'} className="btn btn-warning mt-5 w-fit">
        <p>{t('profile.reset_password')}</p>
      </Link>
    </div>
  );
}

export default Profile;
