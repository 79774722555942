import { useState, createContext, useEffect } from "react";

export const AppStateContext = createContext();

const AppStateContextProvider = ({ children }) => {
  const [isdark, setIsdark] = useState(
    JSON.parse(localStorage.getItem('isdark')) || false
  );
  useEffect(() => {
    localStorage.setItem('isdark', JSON.stringify(isdark));
  }, [isdark]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [salon, setSalon] = useState({});
  const [locale, setLocale] = useState({
    currency: 'EUR',
    languages: 'es-ES,ca'
  });

  useEffect(() => {
    if (salon) {
      setLocale(salon?.locale);
    }
  }, [salon]);

  const [salons, setSalons] = useState({});
  const [editingSalon, setEditingSalon] = useState({});
  const saveSalon = (updatedSalon) => {
    setSalon(updatedSalon)
    setSalons(prev => ({ ...prev, [updatedSalon.id]: updatedSalon }));
  }

  const [salonProducts, setSalonProducts] = useState({});
  const [editingProduct, setEditingProduct] = useState({});
  const saveProduct = (updatedProduct) => {
    setSalonProducts(prev => ({ ...prev, [updatedProduct.id]: updatedProduct }));
  };

  const [employees, setEmployees] = useState({});
  const [editingEmployee, setEditingEmployee] = useState({});
  const saveEmployee = (updatedEmployee) => {
    const existingEmployee = Object.values(employees).find((employee) => employee.user.id === updatedEmployee.id);
    setEmployees(prev => ({ ...prev, [existingEmployee.id]: { ...existingEmployee, user: updatedEmployee } }));
  };
  const saveSalonEmployee = (updatedEmployee) => {
    setEmployees(prev => ({ ...prev, [updatedEmployee.id]: updatedEmployee }));
  };

  const [editingSlot, setEditingSlot] = useState({});
  const [formSlot, setFormSlot] = useState({
    week_day: 0,
    start_time: '',
    end_time: '',
    week_day_label: '',
    slot_products: []
  });
  const [formPto, setFormPto] = useState({
    start_date: '',
    end_date: '',
  });

  const generalStates = {
    localeState: [locale, setLocale],
    sidebarState: [sidebarOpen, setSidebarOpen],
    salonState: [salon, setSalon, saveSalon],
    salonsState: [salons, setSalons],
    salonFormState: [editingSalon, setEditingSalon, saveSalon],
    salonProductsState: [salonProducts, setSalonProducts],
    productFormState: [editingProduct, setEditingProduct, saveProduct],
    employeesState: [employees, setEmployees],
    employeeFormState: [editingEmployee, setEditingEmployee, saveEmployee, saveSalonEmployee],
    formSlotState: [editingSlot, setEditingSlot, formSlot, setFormSlot],
    ptoFormState: [formPto, setFormPto],
    themeState: [isdark, setIsdark]
  }

  return (
    <AppStateContext.Provider
      value={{
        ...generalStates
      }}
    >
      { children }
    </AppStateContext.Provider>
  )
}

export default AppStateContextProvider;