import './index.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getStartAndEndOfWeek, getDatesInRange } from '../../lib/date';
import { DayHeader } from './components/day_header';
import { TimeSlot } from './components/timeslot';
import moment from 'moment';
import { CalendarContext } from '../../contexts/CalendarContext';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import AppointmentModal from './components/appointment/modal';
import { AppStateContext } from '../../contexts/AppStateContext';
import { getRandomLightColor } from '../utils';

const Calendar = () => {
  const calendarRef = useRef(null);
  const { t } = useTranslation();
  const { salonState } = useContext(AppStateContext)
  const [salon,] = salonState
  const { selectedDate, selectedEmployee, appointments, handleLastWeek, handleNextWeek, setSelectedTimeSlot, resetForm, setFormAppointment } = useContext(CalendarContext);
  const now = moment();
  const [isDragging, setIsDragging] = useState(false)
  const [startYPosition, setStartYPosition] = useState(null)

  const weekDays = t('dates.weekDays.short', { returnObjects: true });
  const months = t('dates.months.short', { returnObjects: true });
  const hours = [
    '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00',
    '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
    '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
  ];
  const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(selectedDate);
  const days = getDatesInRange(startOfWeek, endOfWeek);

  function scrollToTime(date) {
    const weekday = date.format('ddd');
    const hour = date.format('H');
    const targetRow = document.querySelector(`.calendar-item[data-hour="${hour}:00"][data-weekday="${weekday}"]`);
    if (targetRow) {
      targetRow.scrollIntoView({ block: 'start' });
    }
    if (date.day() === 1) {
      calendarRef.current.scrollBy({ left: -50 });
    }
  }

  useEffect(() => {
    scrollToTime(moment(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    const swipeArea = calendarRef.current;
    const handleSwipe = (direction) => {
      if (direction === 'left') {
        handleNextWeek();
      } else if (direction === 'right') {
        handleLastWeek();
      }
    };

    let touchStartX = 0;
    let touchEndX = 0;
    let scrollLeft = null;
    let scrollWidth = null;
    let clientWidth = null;
    let startAtRightEdge = null;
    let startAtLeftEdge = null;

    const onTouchStart = (e) => {
      touchStartX = e.changedTouches[0].screenX;
      scrollLeft = swipeArea.scrollLeft;
      scrollWidth = swipeArea.scrollWidth;
      clientWidth = swipeArea.clientWidth;
      startAtRightEdge = scrollLeft + clientWidth >= scrollWidth;
      startAtLeftEdge = scrollLeft === 0;
    };

    const onTouchEnd = (e) => {
      touchEndX = e.changedTouches[0].screenX;

      scrollLeft = swipeArea.scrollLeft;
      scrollWidth = swipeArea.scrollWidth;
      clientWidth = swipeArea.clientWidth;

      // Swipe left (next week) if at the right edge and exceed the threshold
      if (startAtRightEdge && touchEndX < touchStartX - 100) {
        handleSwipe('left');
      }
      // Swipe right (last week) if at the left edge and exceed the threshold
      else if (startAtLeftEdge && touchEndX > touchStartX + 100) {
        handleSwipe('right');
      }
    };

    swipeArea.addEventListener('touchstart', onTouchStart);
    swipeArea.addEventListener('touchend', onTouchEnd);

    return () => {
      swipeArea.removeEventListener('touchstart', onTouchStart);
      swipeArea.removeEventListener('touchend', onTouchEnd);
    };
  }, [selectedDate, handleNextWeek, handleLastWeek]);

  const filteredAppointments = () => {
    return Object.values(appointments).filter(appointment => {
      return !selectedEmployee.id || (appointment.employee?.id === selectedEmployee.id)
    });
  };

  const GapComponent = ({ start_time, end_time }) => {
    const startTime = moment(start_time, 'HH:mm');
    const endTime = moment(end_time, 'HH:mm');
    const durationInMinutes = endTime.diff(startTime, 'minutes');
    const topPosition = startTime.diff(moment('00:00', 'HH:mm'), 'minutes');

    return (
      <div
        className='absolute w-full bg-base-200'
        style={{
          top: `${topPosition}px`,
          height: `${durationInMinutes}px`
        }}
      >
      </div>
    );
  };

  function roundToNearestFactorOfFive(num) {
    return Math.round(num / 5) * 5;
  }

  // function showCreateModal(event) {
  //   const slot = event.target
  //   // Only trigger if not dragging
  //   if (!isDragging) {
  //     setSelectedTimeSlot(event.target.id)
  //     let rect = slot.getBoundingClientRect();
  //     let yPosition = event.clientY - rect.top;

  //     const hour = Number(slot.dataset.hour.split(':')[0]);
  //     const minutes = roundToNearestFactorOfFive(yPosition);
  //     let start_date = slot.dataset.day?.hour(hour).minute(minutes).toDate()
  //     resetForm({
  //       start_date: start_date,
  //       end_date: moment(start_date).add(15, 'minutes').toDate(),
  //       first_name: '',
  //       salon_products: [],
  //       color_style: getRandomLightColor()
  //     });
  //     document.getElementById('appointment-form').showModal();
  //   }
  // }

  // Start dragging
  function handleMouseDown(event) {
    const slot = event.target.closest('.calendar-item')
    if (event.target.classList.contains('calendar-item')) {
      setSelectedTimeSlot(slot.id)
      let rect = slot.getBoundingClientRect();
      const containerRect = document.getElementById('calendar').getBoundingClientRect();
      let yPosition = event.clientY - rect.top;
      const minutes = roundToNearestFactorOfFive(yPosition);
      setIsDragging(true);
      setStartYPosition(event.clientY - containerRect.top);
      const hour = Number(slot.dataset.hour.split(':')[0]);
      let start_date = moment(slot.dataset.day).hour(hour).minute(minutes).toDate()
      resetForm({
        start_date: start_date,
        end_date: moment(start_date).add(60, 'minutes').toDate(),
        first_name: '',
        salon_products: [],
        color_style: getRandomLightColor()
      });
    }
  }

  // Update end date during dragging
  function handleMouseMove(event) {
    if (isDragging) {
      const containerRect = document.getElementById('calendar').getBoundingClientRect();
      let yPosition = event.clientY - containerRect.top;

      const newEndMinutes = yPosition - startYPosition
      setFormAppointment((prevForm) => ({
        ...prevForm,
        end_date: moment(prevForm.start_date).add(newEndMinutes, 'minutes').toDate()
      }));
    }
  }

  // Stop dragging
  function handleMouseUp(event) {
    if (isDragging) {
      setIsDragging(false);
      document.getElementById('appointment-form').showModal();
    }
  }

  return (
    <>
      <div id='calendar'
           className="relative max-h-[calc(100vh-12rem)] bg-base-100 border rounded-lg"
           onMouseDown={handleMouseDown}
           onMouseMove={handleMouseMove}
           onMouseUp={handleMouseUp}
      >
        {/* Outer container that handles both horizontal and vertical scrolling */}
        <div className="h-full w-full overflow-auto" ref={calendarRef}>
          {/* Main Content with WeekDays and Hour Blocks */}
          <div className="min-w-[890px] h-fit">
            {/* Weekday Headers (Sticky at the top) */}
            <div className="sticky top-0 z-30 flex shadow-md">
              <div className="min-w-[50px] h-[64px] sticky left-0 bg-base-100">
                <div className='flex items-center justify-center h-full'>
                  <span className='capitalize text-center font-extrabold text-xs sm:text-lg' onClick={() => { scrollToTime(moment(selectedDate))}}>{months[moment(selectedDate).format('M') - 1]}</span>
                </div>
              </div>
              {
                days.map((day, index) => {
                  const wDay = weekDays[day.format('d')];
                  return (
                    <div key={index} className="min-w-[120px] h-[64px] grow flex items-center justify-center bg-base-100 border-base-200">
                      <DayHeader key={day.format('ddd')} day={day} wDay={wDay} />
                    </div>
                  )
                })
              }
            </div>

            {/* Scrollable Content (Vertical & Horizontal Scroll) */}
            <div className="flex relative">
              {/* Hours Column (scrolls vertically but sticky on the left) */}
              <div className="min-w-[50px] sticky left-0 z-10">
                {hours.map((hour, index) => (
                  <div key={index} className="hour bg-base-100 h-[60px] flex items-center justify-center">
                    <div className='hour-text'>
                      {index === 0 ? null : hour}
                    </div>
                  </div>
                ))}
              </div>

              {/* Calendar Items (Scrollable horizontally and vertically) */}
              <div className="flex-1 flex">
                {
                  days.map((day, dayIndex) => {
                    const scheduleSlots = salon?.schedule.slots.filter(slot => slot.week_day === dayIndex)
                    const sortedSlots = scheduleSlots.sort(
                      (a, b) => moment(a.start_time, 'HH:mm').diff(moment(b.start_time, 'HH:mm'))
                    );

                    const dayStart = '00:00';
                    const dayEnd = '24:00';
                    const components = [];

                    // Handle gap before the first slot (if applicable)
                    const firstSlotStart = sortedSlots[0]?.start_time || dayEnd;
                    if (moment(firstSlotStart, 'HH:mm').isAfter(moment(dayStart, 'HH:mm'))) {
                      components.push(<GapComponent key="gap-start" start_time={dayStart} end_time={firstSlotStart} />);
                    }

                    // Handle gaps between slots
                    for (let i = 0; i < sortedSlots.length; i++) {
                      const slot = sortedSlots[i];

                      const nextSlotStart = sortedSlots[i + 1]?.start_time || dayEnd;
                      if (moment(slot.end_time, 'HH:mm').isBefore(moment(nextSlotStart, 'HH:mm'))) {
                        components.push(
                          <GapComponent key={`gap-${i}`} start_time={slot.end_time} end_time={nextSlotStart} />
                        );
                      }
                    }

                    return <>
                      <div key={dayIndex} className="min-w-[120px] grow relative">
                        {components}
                        {
                          hours.map((hour) => {
                            const current = day.isSame(now, 'day') && now.format('HH:00') === hour
                            const weekday = day.toLocaleString('default', { weekday: 'short' });
                            return <TimeSlot key={`${weekday}-${hour}`} now={now} current={current} hour={hour} day={day} appointments={filteredAppointments()} />
                          })
                        }
                      </div>
                    </>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppointmentModal />
    </>
  );
}

export default Calendar;
