import { useContext, useEffect, useRef } from "react";
import Calendar from "../../components/calendar"
import { CalendarContext } from "../../contexts/CalendarContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatepickerTW from 'react-tailwindcss-datepicker';
import List from "./list";
import { AppStateContext } from "../../contexts/AppStateContext";
import { fetchAppointments } from '../../lib/api_service/appointments';
import moment from "moment";
import { getDatesInRange, getStartAndEndOfWeek } from "../../lib/date";
import AppointmentForm from "./form";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";


const AppointmentsView = () => {
  const { t, i18n } = useTranslation();
  const {
    appointments, setAppointments,
    resetForm, selectedDate, setSelectedDate,
    selectedTab, setSelectedTab,
    selectedEmployee, setSelectedEmployee,
    fetchedDates, setFetchedDates,
    handleNextWeek, handleLastWeek
  } = useContext(CalendarContext);
  const { handleAuthResponse } = useAuth();
  const appointmentsRef = useRef(appointments);
  const selectedDateRef = useRef(selectedDate);
  const fetchedDatesRef = useRef(fetchedDates);

  const { salonState, employeesState } = useContext(AppStateContext);
  const [salon,,] = salonState;
  const [employees,] = employeesState;

  const handleValueChange = (newValue) => {
    setSelectedDate(new Date(newValue.startDate || new Date()));
  }

  const handleCreate = (emp) => {
    resetForm({
      start_date: selectedDate
    })
    document.getElementById('appointment-form').showModal()
  }

  useEffect(() => {
    appointmentsRef.current = appointments; // Always keep the ref current
  }, [appointments]);

  useEffect(() => {
    selectedDateRef.current = selectedDate; // Always keep the ref current
  }, [selectedDate]);

  useEffect(() => {
    fetchedDatesRef.current = fetchedDates; // Always keep the ref current
  }, [fetchedDates]);

  useEffect(() => {
    if (selectedDate && salon?.id) {
      const getRandomLightColor = () => {
        const base = 200;
        const darkenFactor = 20;
        const red = base + Math.floor(Math.random() * (255 - base));
        const green = base + Math.floor(Math.random() * (255 - base));
        const blue = base + Math.floor(Math.random() * (255 - base));
        const darkerRed = Math.max(0, red - darkenFactor);
        const darkerGreen = Math.max(0, green - darkenFactor);
        const darkerBlue = Math.max(0, blue - darkenFactor);

        return {
          backgroundColor: `rgb(${red}, ${green}, ${blue}, 1)`,
          borderColor: `rgb(${darkerRed}, ${darkerGreen}, ${darkerBlue}, 1)`,
        };
      }

      const formatAppointments = (appointment, overlaps) => {
        return {
          id: appointment.id,
          uuid: appointment.uuid,
          start_date: new Date(appointment.start_date),
          end_date: new Date(appointment.end_date),
          customer: appointment.customer,
          employee: appointment.employee,
          salon_products: appointment.salon_products,
          overlap: overlaps[appointment.id]?.count,
          position: overlaps[appointment.id]?.pos,
          color_style: getRandomLightColor(),
          paid: appointment.paid,
          payment_method: appointment.payment_method,
          google_meet_url: appointment.google_meet_url,
          appointment_type: appointment.appointment_type,
          description: appointment.description
        }
      }
      const formattedDate = moment(selectedDate).format('DD/MM/YYYY')
      const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(selectedDate);
      const days = getDatesInRange(startOfWeek, endOfWeek);

      if (!fetchedDatesRef.current[formattedDate]) {
        fetchAppointments(salon, formattedDate)
          .then(handleAuthResponse)
          .then((data) => {
            if (data) {
              let newAppointments = data.appointments.reduce((acc, currentObj) => {
                acc[currentObj.id] = formatAppointments(currentObj, data.overlaps);
                return acc;
              }, {...appointmentsRef.current})
              setAppointments(newAppointments)

              const newFetchedDates = days.reduce((acc, current) => {
                acc[moment(current).format('DD/MM/YYYY')] = true;
                return acc;
              }, {...fetchedDatesRef.current})
              setFetchedDates(newFetchedDates)
            }
          })
      }
    }
  }, [selectedDate, salon, setAppointments, setFetchedDates, handleAuthResponse])

  return (
    <div className="drawer drawer-end">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col gap-2">
        <div className="time-header flex items-center gap-2 lg:mb-0">
          <button className='btn btn-outline border-none btn-success text-2xl' onClick={handleCreate}>
            <FontAwesomeIcon icon="fa-regular fa-calendar-plus" />
          </button>
          <button className='btn btn-outline' onClick={handleLastWeek}>
            <FontAwesomeIcon icon="fa-solid fa-angle-left" />
          </button>
          <DatepickerTW
            useRange={false}
            asSingle={true}
            value={{ startDate: selectedDate, endDate: selectedDate }}
            startFrom={selectedDate}
            onChange={handleValueChange}
            displayFormat={"ddd - MMM DD, YYYY"}
            readOnly={true}
            i18n={i18n.language}
            containerClassName='z-40 relative w-full text-gray-700'
          />
          <button className='btn btn-outline'onClick={handleNextWeek}>
            <FontAwesomeIcon icon="fa-solid fa-angle-right" />
          </button>
        </div>
        <div className="flex gap-2">
          <button className={`flex-1 sm:flex-none sm:w-32 btn btn-success ${selectedTab === 'list' ? '' : 'btn-outline'}`} onClick={() => setSelectedTab('list')}>
            <FontAwesomeIcon icon="fa-solid fa-rectangle-list" size="2xl" />
          </button>
          <button className={`flex-1 sm:flex-none sm:w-32 btn btn-success ${selectedTab === 'calendar' ? '' : 'btn-outline'}`} onClick={() => setSelectedTab('calendar')}>
            <FontAwesomeIcon icon="fa-solid fa-calendar" size="2xl" />
          </button>
          <label htmlFor="my-drawer-4" className={`drawer-button sm:flex-none ms-auto btn btn-success btn-outline`}>
            <FontAwesomeIcon icon={`fa-solid fa-${ selectedEmployee.id ? 'user' : 'house' }`} size="2xl" />
          </label>
        </div>
        { selectedTab === 'list' ? <List /> : <Calendar /> }
        <AppointmentForm />
      </div>
      <div className="drawer-side z-50">
        <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
        <ul className="menu bg-base-200 text-base-content min-h-full w-80 p-4 gap-2">
          <label
            className={`drawer-button btn btn-accent capitalize ${ selectedEmployee.id ? 'btn-outline' : '' }`}
            onClick={() => { setSelectedEmployee({}) }}
            htmlFor="my-drawer-4"
          >
            {t('business')}
          </label>
          {
            Object.values(employees)?.map((employee) => {
              const isSelected = selectedEmployee.id === employee.id
              return  <label
                        key={`employee-${employee.id}`}
                        className={`drawer-button btn btn-success ${ isSelected ? '' : 'btn-outline' }`}
                        onClick={() => { setSelectedEmployee(employee) }}
                        htmlFor="my-drawer-4"
                      >
                        {employee.user.full_name}
                      </label>
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default AppointmentsView