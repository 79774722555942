import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { AppStateContext } from '../../contexts/AppStateContext';
import Skeleton from '../../components/utils/skeleton';
import { toast } from 'react-toastify';

const AuthenticatedRoute = () => {
  const { isAuthenticated, handleAuthResponse } = useAuth();
  const { salonProductsState, salonState, salonsState, employeesState } = useContext(AppStateContext);
  const [loading, setLoading] = useState(true)
  const [salon, setSalon,] = salonState;
  const [salons, setSalons] = salonsState;
  const [, setSalonProducts] = salonProductsState;
  const [, setEmployees] = employeesState;

  const salonRef = useRef(salon);
  useEffect(() => {
    if (salonRef.current !== salon) {
      salonRef.current = salon;
      localStorage.setItem('salonId', salon.id);
    }
  }, [salon]);

  const salonsRef = useRef(salons);
  useEffect(() => {
    if (salonsRef.current !== salons) {
      salonsRef.current = salons;
    }
  }, [salons]);

  useEffect(() => {
    if (salonRef.current.id) {
      const selectedSalon = salonsRef.current[salonRef.current.id || localStorage.getItem('salonId')]
      let newProducts = selectedSalon?.salon_products?.reduce((acc, currentObj) => {
        acc[currentObj.id] = currentObj;
        return acc;
      }, {})
      let newEmployees = selectedSalon?.salon_employees?.reduce((acc, currentObj) => {
        acc[currentObj.id] = currentObj;
        return acc;
      }, {})
      setSalonProducts(newProducts)
      setEmployees(newEmployees)
    }
  }, [salon, setSalonProducts, setEmployees])

  useEffect(() => {
    async function fetchSalons() {
      fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(handleAuthResponse)
        .then(data => {
          if (!data) {
            toast.warning('Debes iniciar session nuevamente.')
            return;
          }

          let newSalons = data.reduce((acc, currentObj) => {
            acc[currentObj.id] = currentObj;
            return acc;
          }, {})
          setSalons(newSalons)
          if (!salonRef.current.uuid) {
            const localSalonId = localStorage.getItem('salonId');
            setSalon(newSalons[localSalonId] || data[0])
          }
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
        });
    }
    fetchSalons();
  }, [setSalon, setSalons, handleAuthResponse])

  return isAuthenticated ? (loading ? <Skeleton /> : <Outlet />) : <Navigate to="/admin/sign_in" />;
};

export default AuthenticatedRoute;
