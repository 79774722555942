export async function passwordResetRequest(email) {
  const body = {
    user: { email }
  }
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/users/password`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
}

export async function passwordReset(token, password, passwordConfirmation) {
  const body = {
    user: {
      reset_password_token: token,
      password,
      password_confirmation: passwordConfirmation
    }
  }
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/users/password`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
}