import { useContext } from "react";
import Appointment from "../appointment";
import { CalendarContext } from "../../../../contexts/CalendarContext";
import moment from "moment";

export const TimeSlot = ({ now, current, hour, day, appointments }) => {
  const { selectedTimeSlot, formAppointment } = useContext(CalendarContext);

  // Get weekday using moment's format method
  const weekday = day.format('ddd'); // Short weekday format (Mon, Tue, etc.)

  // Extract hour, day, and month from the moment object
  const slotHour = hour.split(':')[0];
  const slotDay = day.date(); // Use moment's date() to get the day of the month
  const slotMonth = day.month(); // Use moment's month() to get the month (0-11)

  // Convert appointment dates to moment for easier comparison
  function minutesRemaining(date) {
    const end = moment(date).endOf('day');
    const minutes = end.diff(moment(date), 'minutes');
    return minutes;
  }

  function minutesSpanned(date) {
    const start = moment(date).startOf('day');
    const minutes = moment(date).diff(start, 'minutes');
    return minutes;
  }

  function appointmentMatchesTimeSlot(appointment) {
    return startMatchesSlot(appointment) ||
      (endMatchesSlot(appointment) && appointmentOverflowsFromPreviousDay(appointment)) ||
      appointmentSpansSlotDay(appointment);
  }

  function startMatchesSlot(appointment) {
    const startHour = moment(appointment.start_date).hour();
    const startDay = moment(appointment.start_date).date();
    const startMonth = moment(appointment.start_date).month();
    return Number(slotHour) === Number(startHour) && slotDay === startDay && slotMonth === startMonth;
  }

  function endMatchesSlot(appointment) {
    const endHour = moment(appointment.end_date).hour();
    const endDay = moment(appointment.end_date).date();
    const endMonth = moment(appointment.end_date).month();
    return Number(slotHour) === Number(endHour) && slotDay === endDay && slotMonth === endMonth;
  }

  function appointmentSpansSlotDay(appointment) {
    const startDay = moment(appointment.start_date).date();
    const endDay = moment(appointment.end_date).date();
    const startBeforeDay = startDay < slotDay;
    const endAfterDay = endDay > slotDay;
    const firstHour = Number(slotHour) === 0;
    return firstHour && startBeforeDay && endAfterDay ? 24 * 60 + 23 : false;
  }

  function appointmentOverflowsFromPreviousDay(appointment) {
    const start_date = moment(appointment.start_date).startOf('day');
    const startBeforeDay = start_date.isBefore(day);
    return startBeforeDay && endMatchesSlot(appointment) ? minutesSpanned(appointment.end_date) : false;
  }

  function appointmentOverflowsToNextDay(appointment) {
    const end_date = moment(appointment.end_date).startOf('day');
    const endAfterDay = end_date.isAfter(day);
    return startMatchesSlot(appointment) && endAfterDay ? minutesRemaining(appointment.start_date) : false;
  }

  return (
    <div  key={`${weekday}-${hour}`}
          id={`slot-${weekday}-${hour}`}
          data-hour={hour}
          data-weekday={weekday}
          data-day={day.toDate()}
          className='calendar-item relative h-[60px] border border-base-300/40 border-l flex items-center justify-center'
    >
      { current && <div className='absolute w-full z-20 border border-sky-500 opacity-50' style={{ height: '0px', top: now.minutes() }}></div> }
      { selectedTimeSlot === `slot-${weekday}-${hour}` && formAppointment.start_date && <Appointment
          key={formAppointment.id}
          appointment={formAppointment}
          height={
            appointmentOverflowsToNextDay(formAppointment) ||
              appointmentOverflowsFromPreviousDay(formAppointment) ||
                appointmentSpansSlotDay(formAppointment)
          }
          under={appointmentOverflowsToNextDay(formAppointment)}
          over={appointmentOverflowsFromPreviousDay(formAppointment)}
          entireDay={appointmentSpansSlotDay(formAppointment)}
        />
      }
      {
        appointments
          .filter(appointment => appointmentMatchesTimeSlot(appointment))
          .map((appointment) => {
            return <Appointment
              key={appointment.id}
              appointment={appointment}
              height={
                appointmentOverflowsToNextDay(appointment) ||
                  appointmentOverflowsFromPreviousDay(appointment) ||
                    appointmentSpansSlotDay(appointment)
              }
              under={appointmentOverflowsToNextDay(appointment)}
              over={appointmentOverflowsFromPreviousDay(appointment)}
              entireDay={appointmentSpansSlotDay(appointment)}
            />
          })
      }
    </div>
  );
};