import React, { useContext } from 'react'
import { AppStateContext } from '../../contexts/AppStateContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const SwitchBusiness = () => {
  const { salonsState, salonState } = useContext(AppStateContext);
  const [salons,] = salonsState;
  const [, setSalon,] = salonState;
  const navigate = useNavigate();

  const handleSalonChange = (salon) => {
    try {
      setSalon(salon)
      navigate('/admin/dashboard')
      toast.success(`Salon changed to ${salon.name}`)
    } catch (error) {
      toast.error('Error changing salon')
    }
  }

  return (
    <div className='flex flex-wrap justify-around gap-10 mt-10'>
      {
        Object.values(salons).map(salon => {
          return (
            <div key={salon.uuid} className="card w-96 bg-base-300 hover:shadow-xl shadow-md shadow-slate-800 cursor-pointer" onClick={() => { handleSalonChange(salon) }}>
              <div className="card-body">
                <h2 className="card-title">{salon.name}</h2>
                <p>{salon.email}</p>
                <p>{salon.phone_number}</p>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default SwitchBusiness