import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  return (
    <h2 className='text-white w-full text-center bg-primary py-6 align-middle'><FontAwesomeIcon icon="fa-brands fa-cc-stripe" size='2xl'/> {t('stripe.online_payments')}</h2>
  )
}

export default Header