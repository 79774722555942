import { CalendarContextProvider } from "../../contexts/CalendarContext"
import AppointmentsView from "../../components/appointments";


const Appointments = () => {
  return (
    <CalendarContextProvider>
      <AppointmentsView />
    </CalendarContextProvider>
  )
}

export default Appointments