import React from 'react';
import { useTranslation } from 'react-i18next';
import I18nSelect from '../components/utils/i18n_select';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12">
      <div className="relative w-full max-w-4xl mx-auto bg-white p-8 shadow-md rounded-lg">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">{t('privacyPolicy.title')}</h1>
        <div className="absolute top-0 end-0">
          <I18nSelect />
        </div>
        <p className="text-gray-600 mb-4">
          {t('privacyPolicy.effectiveDate', { date: 'July 20, 2024' })}
        </p>
        <p className="text-gray-600 mb-4">{t('privacyPolicy.intro')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacyPolicy.collection.title')}</h2>
        <p className="text-gray-600 mb-4">{t('privacyPolicy.collection.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacyPolicy.use.title')}</h2>
        <p className="text-gray-600 mb-4">{t('privacyPolicy.use.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacyPolicy.protection.title')}</h2>
        <p className="text-gray-600 mb-4">{t('privacyPolicy.protection.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacyPolicy.consent.title')}</h2>
        <p className="text-gray-600 mb-4">{t('privacyPolicy.consent.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacyPolicy.rights.title')}</h2>
        <p className="text-gray-600 mb-4">{t('privacyPolicy.rights.description')}</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacyPolicy.contact.title')}</h2>
        <p className="text-gray-600 mb-4">{t('privacyPolicy.contact.description')}</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
