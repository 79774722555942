export async function fetchAppointments(salon, date, employeeId) {
  const url = new URL(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/appointments?`)
  url.searchParams.append('date', date);
  if (employeeId) { url.searchParams.append('employee_id', employeeId) }
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}

export async function confirmAppointment(salon, appointment) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/appointments/${appointment.id}/confirm`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}

export async function delayAppointment(salon, appointment) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/appointments/${appointment.id}/delay`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}

export async function createAppointment(salon, data) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/appointments`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      appointment: {
        start_date: data.start_date,
        end_date: data.end_date,
        customer: data.customer,
        employee_id: data.employee?.id,
        salon_products: data.salon_products.map((sp) => sp.id),
        appointment_type: data.appointment_type,
        description: data.description
      }
    })
  })
}

export async function updateAppointment(salon, data) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/appointments/${data.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      appointment: {
        start_date: data.start_date,
        end_date: data.end_date,
        customer: data.customer,
        salon_products: data.salon_products.map((sp) => sp.id)
      }
    })
  })
}

export async function deleteAppointment(salon, data) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/appointments/${data.id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}
