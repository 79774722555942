export async function createContact(data) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/contacts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      contact: {
        name: data.name,
        email: data.email,
        message: data.message
      }
    })
  })
}