import React, { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../../contexts/AppStateContext';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { createEmployee, updateEmployee } from '../../lib/api_service/employees';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const animatedComponents = makeAnimated();

const defaultEmployee = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  salon_products: []
};

const EmployeeForm = () => {
  const { salonState, salonProductsState, employeeFormState, localeState } = useContext(AppStateContext);
  const [locale,] = localeState;
  const [salonProducts,] = salonProductsState;
  const [salon,,] = salonState;
  const [editingEmployee, setEditingEmployee,,saveSalonEmployee] = employeeFormState;
  const [formEmployee, setFormEmployee] = useState({...editingEmployee.user, salon_products: editingEmployee.salon_products} || {});
  const { t } = useTranslation();

  const handleEmailChange = (e) => {
    setFormEmployee({
      ...formEmployee,
      email: e.target.value
    });
  };

  const handlePhoneNumberChange = (value) => {
    setFormEmployee({
      ...formEmployee,
      phone_number: value
    });
  };

  const handleNameChange = (e) => {
    setFormEmployee({
      ...formEmployee,
      [e.target.name]: e.target.value
    });
  };

  const handleProductsChange = (options) => {
    setFormEmployee({
      ...formEmployee,
      salon_products: options.map((option) => salonProducts[option.value])
    });
  };

  const validInputs = () => {
    return (formEmployee.phone_number && isValidPhoneNumber(formEmployee.phone_number)) || (formEmployee.email && isValidEmail());
  };

  const isValidEmail = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(formEmployee.email).toLowerCase());
  };

  useEffect(() => {
    if (editingEmployee.id) {
      const user = editingEmployee.user;
      setFormEmployee({
        ...user,
        salon_products: editingEmployee.salon_products || []
      });
    } else {
      setFormEmployee(defaultEmployee);
    }
  }, [editingEmployee]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingEmployee.id) {
      updateEmployee(salon, editingEmployee, formEmployee)
        .then(response => response.json())
        .then(data => {
          if (!data.errors) {
            saveSalonEmployee(data);
            toast.success(t('employees.update.success'));
            resetForm();
          } else {
            toast.error(t('employees.update.error'));
          }
        });
    } else {
      createEmployee(salon, formEmployee)
        .then(response => response.json())
        .then(data => {
          if (!data.errors) {
            saveSalonEmployee(data);
            toast.success(t('employees.create.success'));
            resetForm();
          } else {
            toast.error(t('employees.create.error', { errors: JSON.stringify(data.errors) }));
          }
        });
    }
    document.getElementById('employee-form').close();
  };

  const resetForm = () => {
    setFormEmployee(defaultEmployee);
    setEditingEmployee(defaultEmployee);
  };

  return (
    <dialog id="employee-form" className="modal">
      <div className="modal-box flex flex-col gap-3 overflow-visible">
        <label className="input input-bordered flex items-center gap-5">
          <div className="indicator">
            <span className="indicator-item badge bg-transparent border-0">1</span> 
            <FontAwesomeIcon icon={'fas fa-user'} />
          </div>
          <input type="text" name="first_name" className="grow" placeholder={t('employees.first_name.placeholder')} value={formEmployee.first_name || ''} onChange={handleNameChange}/>
        </label>
        <label className="input input-bordered flex items-center gap-5">
          <div className="indicator">
            <span className="indicator-item badge bg-transparent border-0">2</span> 
            <FontAwesomeIcon icon={'fas fa-user'} />
          </div>
          <input type="text" name="last_name" className="grow" placeholder={t('employees.last_name.placeholder')} value={formEmployee.last_name || ''} onChange={handleNameChange}/>
        </label>
        <label className="input input-bordered flex items-center gap-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
          <input type="email" className="grow" placeholder={t('employees.email.placeholder')} value={formEmployee.email || ''} onChange={handleEmailChange}/>
        </label>
        <PhoneInput
          placeholder={t('employees.phone.placeholder')}
          value={formEmployee.phone_number || ''}
          onChange={handlePhoneNumberChange}
          defaultCountry={locale?.country_code}
          className='input input-bordered'
        />
        <Select
          placeholder={t('employees.products.placeholder')}
          isMulti
          isSearchable={false}
          value={formEmployee.salon_products?.map((salon_product) => { return { value: salon_product.id, label: salon_product.product } }) || []}
          onChange={handleProductsChange}
          components={animatedComponents}
          options={Object.values(salonProducts)?.map((salonProduct) => {
            return {
              value: salonProduct.id,
              label: salonProduct.product
            };
          })}
          styles={{
            multiValue: () => {},
            control: () => {},
          }}
          classNames={{
            multiValue: () => 'badge badge-info',
            valueContainer: () => 'flex gap-x-2',
            control: () =>
                'input input-ghost flex items-center bg-transparent h-fit',
          }}
        />
        <button className="btn btn-primary" disabled={!validInputs()} onClick={handleSubmit}>{t('save')}</button>
      </div>
      <form method="dialog" className="modal-backdrop" onSubmit={resetForm}>
        <button>{t('close')}</button>
      </form>
    </dialog>
  );
}

export default EmployeeForm;
