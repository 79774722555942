import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // const { authenticationState } = useContext(AppStateContext);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const authenticated = localStorage.getItem('isAuthenticated');
    return (authenticated && authenticated !== undefined && authenticated !== 'undefined') ? JSON.parse(authenticated) : null
  });
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return (savedUser && savedUser !== undefined && savedUser !== 'undefined') ? JSON.parse(savedUser) : null
  });

  const nullifyUser = () => {
    setIsAuthenticated(false)
    setUser(null)
    localStorage.removeItem('user')
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('expires')
  }

  const handleAuthResponse = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then(data => {
        if (data.errors && data.errors.includes("Unauthorized")) {
          // Handle unauthorized error here
          console.error("Unauthorized access detected.");
          console.log("Logging out")
          nullifyUser();
        } else {
          console.error('Error fetching data: ', data);
          // Handle other errors or status codes
        }
      });
    }
  }

  // Mock authentication function
  const login = (email, password) => {
    fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/users/sign_in`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: {
          email: email,
          password: password,
        }
      })
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(data => {
            setIsAuthenticated(true);
            setUser(data.user);
            localStorage.setItem('expires', data.expires);
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('isAuthenticated', JSON.stringify(true));
          });
        } else {
          response.json().then(data => {
            if (data.message === 'Invalid Credentials') {
              toast.error('La combinación de correo y contraseña es incorrecto. Porfavor pruebe de nuevo.')
            } else if (data.message === 'Not authenticatable') {
              toast.error('Debes darte de alta. Porfavor contacte al administrador.')
            } else {
              toast.error('Error al inciar session, contacte al administrador.')
            }
          });
        }
      })
  }

  const logout = () => {
    fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/users/sign_out`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        nullifyUser();
      })
      .catch((error) => {
        console.error('Error signing out user', error)
      })
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, handleAuthResponse, nullifyUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
