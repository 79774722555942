export async function createProduct(salon, data) {
  const body = {
    salon_product: {
      price_cents: Number(String(data.price).replace(',', '.') || 0) * 100,
      price_currency: salon.locale.currency,
      duration: data.duration,
      blocks: data.blocks,
      parallel_limit: data.parallel_limit,
      product_attributes: { name: data.product },
      mode: data.mode,
      prepayment_required: data.prepayment_required,
      payment_methods: data.payment_methods
    }
  }
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/salon_products`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
}

export async function updateProduct(salon, data) {
  const body = {
    salon_product: {
      price_cents: Number(String(data.price).replace(',', '.') || 0) * 100,
      price_currency: salon.locale.currency,
      duration: data.duration,
      blocks: data.blocks,
      parallel_limit: data.parallel_limit,
      product_attributes: { name: data.product },
      mode: data.mode,
      prepayment_required: data.prepayment_required,
      payment_methods: data.payment_methods
    }
  }
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/salon_products/${data.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
}

export async function deleteProduct(salon, data) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/salon_products/${data.id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}
