import { IntakeProvider } from '../../contexts/IntakeContext';
import RescheduleStages from './stages/rescheduleStages';

const Reschedule = () => {
  return (
    <IntakeProvider>
      <RescheduleStages />
    </IntakeProvider>
  );
};

export default Reschedule;
