import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { components } from 'react-select';

export const CustomOption = (props) => {
  const { t } = useTranslation();
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className='flex-1 text-black'>{props.data.label}</span>
        <div className="flex gap-2 items-center">
          {
            props.data.online_payments && <div className="tooltip tooltip-left" data-tip={t('intake.checkout.payable_online')}>
              <FontAwesomeIcon icon="fa-regular fa-credit-card" className="text-black" />
            </div>
          }
          {
            props.data.mode === 'online' && <div className="tooltip tooltip-left" data-tip={t('videoCall')}>
              <FontAwesomeIcon icon="fa-solid fa-video" className="text-black"/>
            </div>
          }
          {
            props.data.free && <div className='badge badge-success badge-outline'>
              { t('free') }
            </div>
          }
        </div>
      </div>
    </components.Option>
  );
};

export const CustomSlotOption = (props) => {
  const { t } = useTranslation();
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className='flex-1 text-black'>{props.data.label}</span>
        <div className="flex gap-2 items-center">
          {
            props.data.mode === 'online' && <div className="tooltip tooltip-left" data-tip={t('videoCall')}>
              <FontAwesomeIcon icon="fa-solid fa-video" className="text-black"/>
            </div>
          }
        </div>
      </div>
    </components.Option>
  );
};

export const CustomSingleValue = (props) => {
  const { t } = useTranslation();
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span className='flex-1 text-black'>{props.data.label}</span>
        <div className="flex gap-2 items-center">
          {
            props.data.mode === 'online' && <div className="tooltip tooltip-left" data-tip={t('videoCall')}>
              <FontAwesomeIcon icon="fa-solid fa-video" className="text-black"/>
            </div>
          }
        </div>
      </div>
    </components.SingleValue>
  );
};