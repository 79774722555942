
export async function accountLink(salon) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/stripe/account/link`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: 'include',
    body: JSON.stringify({
      account: salon?.stripe_account_id,
    }),
  })
}

export async function accountCreate(salon) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/stripe/account`, {
    method: "POST",
    credentials: 'include'
  })
}

export async function getAccount(salon) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/stripe/account`, {
    credentials: 'include'
  })
}