import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { AppStateContext } from '../../contexts/AppStateContext';
import { useTranslation } from 'react-i18next';
import I18nSelect from '../../components/utils/i18n_select';
import ThemeSelect from '../../components/utils/theme_select';

const CheckoutCancellation = () => {
  const [salon, setSalon] = useState({})
  const { localeState } = useContext(AppStateContext);
  const [appointment, setAppointment] = useState({});
  const [, setLocale] = localeState;
  const { t } = useTranslation();

  let { appointmentUUID, checkoutSessionID } = useParams();

  useEffect(() => {
    const fetchAppointment = async () => {
      const url = new URL(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/reservations/${appointmentUUID}/cancel_checkout`)
      url.searchParams.append('checkout_session_id', checkoutSessionID);

      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(data => {
          if (!data.errors) {
            setSalon(data.salon)
            setLocale(data.salon.locale)
            setAppointment(data.appointment)
          } else {
            toast.error(data.errors.join(','))
          }
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
        });
    }

    fetchAppointment();
  }, [appointmentUUID, setAppointment, checkoutSessionID, setLocale])

  const copyToClipboard = async (e) => {
    try {
      await navigator.clipboard.writeText(e.target.dataset.copy);
      toast.success('copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast.error('Failed to copy');
    }
  };

  const showAddress = () => {
    const offlineProducts = appointment.salon_products?.filter((sp) => sp.mode === 'offline')
    return offlineProducts?.length > 0
  }

  return (
    <div className='container mx-auto p-4'>
      <div className="absolute top-0 end-0">
        <I18nSelect />
      </div>
      <div className='flex flex-col gap-3'>
        <div className='mx-3'>
          <div className="flex items-center justify-between mt-5">
            <h1 className='text-4xl'>{salon?.name}</h1>
            <ThemeSelect />
          </div>
          <div className="flex lg:justify-start gap-3 my-3">
            <a className='btn btn-lg btn-primary' href={`mailto:${salon.email}`}>
              <FontAwesomeIcon icon="fa-solid fa-envelope" size='2x'/>
            </a>
            <a className='btn btn-lg btn-secondary' href={`tel:${salon.phone_number}`}>
              <FontAwesomeIcon icon="fa-solid fa-phone" size='2x'/>
            </a>
            {
              salon?.address && showAddress() ? <a className='btn btn-lg btn-accent' href={salon.google_url} target='_blank' rel="noreferrer">
                <FontAwesomeIcon icon="fa-solid fa-map-location-dot" size='2x'/>
              </a> : null
            }
          </div>
          <div className='flex flex-col gap-1'>
            {
              salon?.email ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.email}>
                <FontAwesomeIcon icon="fa-solid fa-envelope" className='me-3'/>
                {salon?.email}
              </p> : null
            }
            {
              salon?.phone_number ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.phone_number}>
                <FontAwesomeIcon icon="fa-solid fa-phone" className='me-3' />
                {formatPhoneNumberIntl(salon?.phone_number)}
              </p> : null
            }
            {
              salon?.address && showAddress() ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.address}>
                <FontAwesomeIcon icon="fa-solid fa-map-location-dot" className='me-3' />
                {salon?.address}
              </p> : null
            }
          </div>
        </div>
        <div className="divider"></div>
        <div className='flex flex-col items-center'>
          <h2 className='text-3xl text-light'>{t('cancellation.appointment')} { appointment.status === 'cancelled' ? t('cancellation.cancelled') : t('cancellation.pending') }</h2>
          <p className='my-10 text-xl text-center text-light'>
            {t('cancellation.instruction')}
          </p>
          <Link to={`${process.env.REACT_APP_FRONTEND_SERVICE}/${salon?.uuid}/citas`} className='btn btn-success'>{t('cancellation.new_appointment')}</Link>
        </div>
      </div>
    </div>
  )
}

export default CheckoutCancellation