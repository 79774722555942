import React, { useContext } from 'react';
import { AppStateContext } from '../../../contexts/AppStateContext';
import { addPto } from '../../../lib/api_service/employees';
import { toast } from 'react-toastify';
import Datepicker from 'react-tailwindcss-datepicker';
import { useTranslation } from 'react-i18next';

const EmployeePtoForm = () => {
  const defaultPto = {
    start_date: '',
    end_date: '',
  };
  const { salonState, ptoFormState, employeeFormState } = useContext(AppStateContext);
  const [salon,,] = salonState;
  const [formPto, setFormPto] = ptoFormState;
  const [editingEmployee,,,saveSalonEmployee] = employeeFormState;
  const { t, i18n } = useTranslation();

  const handleValueChange = (date) => {
    setFormPto({
      start_date: date.startDate,
      end_date: date.endDate,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addPto(salon, editingEmployee, formPto)
      .then(response => response.json())
      .then((data) => {
        if (data.errors) {
          toast.error(t('vacations.save_error', { error: data.errors }));
        } else {
          saveSalonEmployee(data);
          toast.success(t('vacations.saved_success'));
          resetForm();
        }
      });
    document.getElementById('employee-pto-form').close();
  };

  const resetForm = () => {
    setFormPto(defaultPto);
  };

  return (
    <dialog id="employee-pto-form" className="modal">
      <div className="modal-box flex flex-col gap-3 overflow-visible">
        <Datepicker
          key={formPto.start_date + formPto.end_date}
          onChange={handleValueChange}
          value={{ startDate: formPto.start_date, endDate: formPto.end_date }}
          label={t('vacations.start_date')}
          displayFormat={"MMM DD, YYYY"}
          i18n={i18n.language}
        />
        <button className="btn btn-primary" onClick={handleSubmit}>{t('save')}</button>
      </div>
      <form method="dialog" className="modal-backdrop" onSubmit={resetForm}>
        <button>{t('close')}</button>
      </form>
    </dialog>
  );
}

export default EmployeePtoForm;
