import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { IntakeContext } from '../../../contexts/IntakeContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidEmail, isValidPhone } from '../../../components/utils';
import { reservationsCheckout, reservationsConfirm, reservationsReschedule } from '../../../lib/api_service/checkout';

const Checkout = ({ reschedule = false }) => {
  const {
    appointment, setAppointment, salon, confirming, setConfirming,
    selectedSlot, totalPrice, validPaymentMethods, acceptedPrivacyAndTerms, setAcceptedPrivacyAndTerms
  } = useContext(IntakeContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const validReservation = () => {
    return selectedSlot && isValidEmail(appointment.customer.email) && isValidPhone(appointment.customer.phone_number) && acceptedPrivacyAndTerms
  }

  const handleReservationConfirmation = () => {
    const customer = appointment.customer
    if ((customer.phone_number && !isValidPhone(customer.phone_number))) {
      toast.error(t('intake.validations.phone'))
    }
    if (!customer.email || (customer.email && !isValidEmail(customer.email))) {
      toast.error(t('intake.validations.email'))
    }
    if (!acceptedPrivacyAndTerms) {
      toast.error(t('intake.validations.terms_and_privacy'))
    }
    if (!validReservation()) return

    if (confirming) { return }

    setConfirming(true)

    if (reschedule) {
      rescheduleAppointment();
      return
    }

    if (!appointment.paid && totalPrice !== 0 && appointment.payment_method?.name === 'online') {
      goToCheckout();
    } else {
      confirmAppointmentDirectly();
    }
  }

  const rescheduleAppointment = () => {
    reservationsReschedule(appointment)
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          toast.error(data.errors.join(', '))
          setConfirming(false)
        } else {
          toast.success(t('intake.confirmation.success'))
          setConfirming(false)
          navigate(`/citas/${data.uuid}/confirmacion`);
        }
      })
  }

  const confirmAppointmentDirectly = () => {
    reservationsConfirm(salon, appointment)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          toast.error(t('intake.confirmation.error'))
          setConfirming(false)
        } else {
          toast.success(t('intake.confirmation.success'))
          setConfirming(false)
          navigate(`/citas/${data.uuid}/confirmacion`);
        }
      })
  }

  const goToCheckout = () => {
    reservationsCheckout(salon, appointment)
      .then((response) => response.json())
      .then((json) => {
        const { url, error } = json;

        if (url) {
          window.location.href = url;
        }

        if (error) {
          toast.error(error)
          setConfirming(false)
        }
      });
  }

  return (
    <>
      {
        (totalPrice !== 0 && validPaymentMethods.length !== 0) &&
          <div className="w-full mx-auto my-3 p-3 sm:p-6 bg-light shadow-md rounded-lg">
            <div className="flex gap-2 justify-between">
              <h2 className="text-2xl font-bold mb-6 text-dark">{t('intake.inputs.paymentMethods.title')}</h2>
              <span className='mb-4 mt-1 text-end'><FontAwesomeIcon icon="fa-solid fa-hand-holding-dollar" className='me-2'/>{t('intake.checkout.prepayment_required')}</span>
            </div>
            <div className="grid grid-cols-1 gap-4">
              {
                salon.payment_methods.map((method) => {
                  const enabledPaymentMethod = validPaymentMethods?.some(vpm => vpm.id === method.id)
                  const isSelected = appointment.payment_method?.id === method.id
                  const selectedClass = enabledPaymentMethod && isSelected ? (
                                          "bg-white shadow-md shadow-gray-300 text-gray-800"
                                        ) : (
                                          "border border-gray-100 text-gray-400"
                                        )
                  const enabledClass = enabledPaymentMethod ? "" :"text-gray-200 btn-disabled"
                  return  <button
                            key={`payment-select-${method.name}`}
                            className={`card cursor-pointer ${selectedClass} ${enabledClass}`}
                            onClick={() => enabledPaymentMethod && setAppointment({ ...appointment, payment_method: method})}
                          >
                            <div className="card-body">
                              <h3 className="card-title text-xl font-semibold">{t(`intake.paymentMethods.${method.name}.title`)}</h3>
                              {
                                enabledPaymentMethod ? (
                                  <p className="text-gray-500 text-start">
                                    {t(`intake.paymentMethods.${method.name}.body`)}
                                  </p>
                                ) : (
                                  <p className="text-gray-400 text-start">
                                    { t(`intake.paymentMethods.disabled.body`) }
                                  </p>
                                )
                              }
                            </div>
                          </button>
                })
              }
            </div>
          </div>
      }
      <div className="mt-auto flex">
        <label className="label cursor-pointer">
          <input
            type="checkbox"
            checked={acceptedPrivacyAndTerms}
            onChange={(e) => setAcceptedPrivacyAndTerms(e.target.checked)}
            className="checkbox checkbox-primary me-2"
          />
          <span className="label-text">
            {`${t('intake.terms_and_privacy.accept')}`}
            <a href="/terms-and-conditions" className="text-blue-500 underline mx-1" target="_blank" rel="noopener noreferrer">
              {t('intake.terms_and_privacy.privacy')}
            </a>
            { t('and') }
            <a href="/terms-and-conditions" className="text-blue-500 underline mx-1" target="_blank" rel="noopener noreferrer">
              {t('intake.terms_and_privacy.terms')}
            </a>
          </span>
        </label>
      </div>
      {
        confirming ? (
          <span className="loading loading-lg loading-spinner text-warning mx-auto"></span>
        ) : (
          <button className='btn btn-primary w-full capitalize' disabled={confirming || !validReservation()} onClick={handleReservationConfirmation}>
            {
              reschedule ? t(`reschedule`) : (
                appointment.payment_method ? t(`intake.paymentMethods.${appointment.payment_method.name}.pay`) : t('intake.confirm')
              )
            }
          </button>
        )
      }
    </>
  )
}

export default Checkout