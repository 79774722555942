// src/components/PasswordResetRequestForm.js
import React, { useState } from 'react';
import { passwordResetRequest } from '../../lib/api_service/users';
import { toast } from 'react-toastify';

const PasswordResetRequestForm = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userConfirmed = window.confirm('¿Estas seguro/a que deseas recuperar tu contraseña?');
    if (!userConfirmed) return;

    try {
      passwordResetRequest(email)
        .then((response) => {
          if (response.ok) {
            toast.success('Hemos enviado el enlace de recuperación a tu correo electrónico!');
          } else {
            toast.error('Error al enviar tu enlace de recuperación.');
          }
        });
    } catch (error) {
      console.error(error);
      toast.error('Error sending password reset link:', error);
    }
  };

  const isValidEmail = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && emailRegex.test(String(email).toLowerCase());
  }

  return (
    <div className='container mt-5'>
      <h1 className='text-center text-3xl'>Recuperación de contraseña</h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4">
        <label className="input input-bordered flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required name="email" className="grow" placeholder="Correo electrónico" />
        </label>
        <button type="submit" className="btn btn-primary" disabled={!isValidEmail()}>Enviar correo de recuperación</button>
      </form>
    </div>
  );
};

export default PasswordResetRequestForm;
