import React, { useContext } from 'react'
import { IntakeContext } from '../../../contexts/IntakeContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-number-input';
import { isValidEmail, isValidPhone } from '../../../components/utils';

const ContactInformation = () => {
  const { locale, appointment, setAppointment } = useContext(IntakeContext);
  const { t } = useTranslation();

  const handleAppointmentChange = (attribute, value) => {
    setAppointment((prev) => {
      return {
        ...prev,
        customer: {
          ...prev.customer,
          [attribute]: value
        }
      }
    })
  }

  const handleEmailChange = (e) => {
    const value = e.target.value
    handleAppointmentChange('email', value)
    if (!appointment.customer.first_name && value.includes('@')) {
      handleAppointmentChange('first_name', value.split('@')[0])
    }
  }

  const handleFirstNameChange = (e) => {
    handleAppointmentChange('first_name', e.target.value)
  }

  const handlePhoneNumberChange = (value) => {
    handleAppointmentChange('phone_number', value || '');
  }

  return (
    <>
      <label className="form-control">
        <label className="input shadow-md shadow-gray-200 bg-gray-50 text-black flex items-center gap-2">
          <FontAwesomeIcon icon="fa-solid fa-user" />
          <input type="text" className="grow" placeholder={t('intake.inputs.name.placeholder')} value={appointment.customer.first_name || ''} onChange={handleFirstNameChange}/>
        </label>
      </label>
      <label className="form-control">
        <label className={`input shadow-md bg-gray-50 text-black ${appointment.customer.email ? (isValidEmail(appointment.customer.email) ? 'shadow-green-200 bg-green-100' : 'shadow-red-300 bg-red-100') : 'shadow-orange-200'} flex items-center gap-2`}>
          <FontAwesomeIcon icon="fa-solid fa-envelope" />
          <input type="email" className="grow" placeholder={t('intake.inputs.email.placeholder')} value={appointment.customer.email || ''} onChange={handleEmailChange}/>
          <div className="label">
            <span className={`label-text-alt ${appointment.customer.email ? (isValidEmail(appointment.customer.email) ? 'text-success' : 'text-error') : 'text-warning'}`}>({t('intake.inputs.email.label1')})</span>
          </div>
        </label>
        <div className="label">
          <span className="label-text-alt"></span>
          <span className="label-text-alt">
            <FontAwesomeIcon icon="fa-solid fa-circle-info" className={`mr-2 ${appointment.customer.email ? (isValidEmail(appointment.customer.email) ? 'text-success' : 'text-error') : 'text-warning'}`}/>
            {t('intake.inputs.email.label2')}
          </span>
        </div>
      </label>
      <PhoneInput
        placeholder={t('intake.inputs.phone.placeholder')}
        value={appointment.customer.phone_number || ''}
        onChange={handlePhoneNumberChange}
        defaultCountry={locale?.country_code}
        className={`input shadow-md shadow-gray-200 bg-gray-50 text-black ${appointment.customer.phone_number ? (isValidPhone(appointment.customer.phone_number) ? 'shadow-green-200 bg-green-100' : 'shadow-red-300 bg-red-100') : ''}`}
      />
    </>
  )
}

export default ContactInformation