import SignInForm from "./sign_in_form";

const SignIn = () => {
  return (
    <div className="w-full sm:w-3/4 md:w-1/2 xl:w-1/3 mx-auto mt-10">
      <SignInForm />
    </div>
  );
};

export default SignIn;