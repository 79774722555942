// src/components/PasswordResetForm.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { passwordReset } from '../../lib/api_service/users';
import { toast } from 'react-toastify';

const PasswordResetForm = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userConfirmed = window.confirm('¿Estas seguro/a que deseas recuperar tu contraseña?');
    if (!userConfirmed) return;

    try {
      passwordReset(token, password, passwordConfirmation)
        .then((response) => {
          if (response.ok) {
            toast.success('Tu contraseña ha sido actualizada! Puede iniciar sessión');
          } else {
            toast.error('Error actualizando contraseña.');
          }
        });
    } catch (error) {
      console.error(error);
      alert('Error actualizando contraseña: ', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4">
      <label className="input input-bordered flex items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" /></svg>
        <input name="password" type="password" className="grow" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </label>
      <label className="input input-bordered flex items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" /></svg>
        <input name="password_confirmation" type="password" className="grow" placeholder="Confirmación de contraseña" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} required/>
      </label>
      <button type="submit" className="btn btn-primary">Recuperar contraseña</button>
    </form>
  );
};

export default PasswordResetForm;
