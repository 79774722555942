export async function createEmployee(salon, userParams) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/employees`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: {
      first_name: userParams.first_name,
      last_name: userParams.last_name,
      email: userParams.email,
      phone_number: userParams.phone_number,
      salon_products: userParams.salon_products.map((sp) => sp.id)
    } })
  })
}

export async function updateEmployee(salon, employee, userParams) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/employees/${employee.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: {
      first_name: userParams.first_name,
      last_name: userParams.last_name,
      email: userParams.email,
      phone_number: userParams.phone_number,
      salon_products: userParams.salon_products.map((sp) => sp.id)
    } })
  })
}

export async function deleteEmployee(salon, salonUser) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/employees/${salonUser.id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}

export async function addSlot(salon, salonUser, slot) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/employees/${salonUser.id}/add_slot`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        slot: {
          week_day: slot.week_day,
          start_time: slot.start_time,
          end_time: slot.end_time,
          slot_products: slot.slot_products
        }
      }
    )
  })
}

export async function modifySlot(salon, salonUser, slot) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/employees/${salonUser.id}/modify_slot/${slot.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        slot: {
          week_day: slot.week_day,
          start_time: slot.start_time,
          end_time: slot.end_time,
          slot_products: slot.slot_products
        }
      }
    )
  })
}

export async function deleteSlot(salon, employee, slot) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/employees/${employee.id}/remove_slot/${slot.id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}

export async function addPto(salon, employee, pto) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/employees/${employee.id}/add_pto`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        pto: pto
      }
    )
  })
}

export async function deletePto(salon, employee, pto) {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salons/${salon?.id}/employees/${employee.id}/remove_pto/${pto.id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
}
