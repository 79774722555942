import React from 'react'

const Skeleton = () => {
  return (
    <div className="container px-10 mt-12">
      <div className="flex flex-col gap-4 w-full">
        <div className="skeleton h-32 w-full"></div>
        <div className="skeleton h-4 w-1/2"></div>
        <div className="skeleton h-4 w-full"></div>
        <div className="skeleton h-4 w-full"></div>
      </div>
    </div>
  )
}

export default Skeleton