import React from 'react'

const ConfirmedCancellation = () => {
  return (
    <div className='container mx-auto p-4'>
      <h1 className='text-center text-warning text-3xl'>Cita cancelada con éxito</h1>
      <p className='my-10 text-xl text-center text-warning'>
        Ya puede cerrar esta ventana.
      </p>
    </div>
  )
}

export default ConfirmedCancellation